<template>
    <hr>
    <div class="form-check" v-for="status in statuses" :key="status.id">
        <input v-model="contractsStore.filters.statusContractFilter" class="form-check-input" type="checkbox"
            :value="status.id" :id="'status-'+status.id">
        <label class="form-check-label" :for="'status-'+status.id">
            {{ status.text }}
        </label>
    </div>
</template>
<script setup>
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { ref } from 'vue';
    const contractsStore = useContractsStore();
    const statuses = ref([
        { id: "1", text: "Резерв без рахунку" },
        { id: "2", text: "Рахунок сплачено" },
        { id: "3", text: "Рахунок виставлено" },
        { id: "4", text: "Рахунок сплачено частково" },
        { id: "5", text: "Відмова від контракту" },
        { id: "6", text: "Продано" }
    ]);
</script>
import IndexView from '@/views/Contracts/IndexView.vue';
import LeadCardView from '@/views/Contracts/LeadCardView.vue';

const parentRoute = '/contracts/:brand';

const contractsRoutes = [
    {
        path: parentRoute + '/',
        name: 'contracts.index',
        component: IndexView
    },
    {
        path: parentRoute + '/:tdriveId/lead-card',
        name: 'contracts.leadCard',
        component: LeadCardView
    },
];

export default contractsRoutes;
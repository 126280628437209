<template>
    <div
        class="bg-white d-flex justify-content-center flex-wrap rounded-pill rounded-xxl rounded-lg px-1 shadow-sm mt-2 mb-2 align-items-center">
        <div class="d-flex justify-content-between">
            <div class="input-group">
                <span class="input-group-text rounded-pill ps-3 my-1 bg-light border-0 rounded-end text-primary "
                    id="basic-addon1">
                    <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-search" />
                </span>
                <input type="text"
                    class="form-control pt-3 pb-3 rounded-pill my-1 bg-light border-0 rounded-start text-primary "
                    aria-describedby="basic-addon1" placeholder="Пошук">
            </div>
        </div>
        <div class="d-flex align-items-center flex-wrap">
            <button class="btn btn-link  mx-1 text-primary"><font-awesome-icon style="font-size: 20px;"
                    icon="fa-solid fa-comment" />
            </button>
            <button class="btn btn-link  mx-1 text-primary position-relative"><font-awesome-icon
                    style="font-size: 20px;" icon="fa-solid fa-bell" />
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    9+
                    <span class="visually-hidden">unread messages</span>
                </span>
            </button>
            <button class="btn btn-link  mx-1 text-primary"><font-awesome-icon style="font-size: 20px;"
                    icon="fa-solid fa-circle-info" />
            </button>
            <button @click.prevent="authStore.logout" class="btn btn-link mx-1 text-primary">
                <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-right-from-bracket" />
            </button>
            <div
                class="rounded-circle bg-light user-icon-wrapper d-flex align-items-center justify-content-center my-1">
                <button class="rounded-circle btn btn-link btn-block  text-primary">
                    <font-awesome-icon style="font-size: 30px;" icon="fa-solid fa-circle-user" />
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { useAuthStore } from '@/stores/authStore';
    const authStore = useAuthStore();
</script>
<style scoped>
    .user-icon-wrapper {
        width: 3rem;
        height: 3rem;
    }
</style>
<template>
    <div class="scroll-wrapper">
        <slot></slot>
    </div>
</template>
<style scoped>
    .scroll-wrapper {
        height: 12vh;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) #f0f0f0;
    }

    .scroll-wrapper::-webkit-scrollbar {
        width: 5px;
    }

    .scroll-wrapper::-webkit-scrollbar-thumb {
        background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
        border-radius: 5px;
    }

    .scroll-wrapper::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }
</style>
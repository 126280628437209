<template>
    <div class="bg-light rounded-pill p-1 m-1">
        <div class="d-flex justify-content-between">
            <div class="input-group">
                <span class="input-group-text rounded-pill ps-3 bg-light border-0 rounded-end text-primary"
                    id="basic-addon1">
                    <font-awesome-icon style="font-size: 20px" icon="fa-solid fa-search" />
                </span>
                <input type="text" v-model="contractsStore.search"
                    class="form-control rounded-pill pt-3 pb-3 bg-light border-0 rounded-start text-primary border border-white"
                    aria-describedby="basic-addon1" placeholder="Пошук" />
            </div>
        </div>
    </div>
</template>
<script setup>
    import { watch } from 'vue';
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { useAuthStore } from '@/stores/authStore';
    import { searchContractsHandlerTimeout } from '@/services/contracts/searchContractsService';

    const contractsStore = useContractsStore();
    const authStore = useAuthStore();

    const handleSearchChange = () => {
        contractsStore.page = 1;
        searchContractsHandlerTimeout({ authStore, contractsStore });
    }
    watch(() => contractsStore.search, handleSearchChange);
</script>
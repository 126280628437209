import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchClientVisitsByVin } from "@/api/contractsApi";

export const clientVisitsHandlerTimeout = ({ clientVisitsStore, payload }) => {
    setTimeout(() => clientVisitsHandlerDebounced({ clientVisitsStore, payload }), 300);
};

const clientVisitsHandlerDebounced = debounce(async function ({ clientVisitsStore, payload }) {
    clientVisitsStore.setStatusPending();
    clientVisitsStore.setMainError(null);

    const { response, error } = await withAsync(fetchClientVisitsByVin, payload);

    if (response) {
        clientVisitsStore.setStatusSuccess();
        clientVisitsStore.clientVisits = response.data;
    } else if (error) {
        clientVisitsStore.setStatusError();
        clientVisitsStore.setMainError(error);
    }
}, 300);





<template>
    <AuthLayout>
        <div class="wrapper">
            <div>
                <div class="row">
                    <div class="col-12 text-center">
                        <p class="text-white fs-1">
                            <b>STELLANTIS PORTAL</b>
                        </p>
                    </div>
                    <div class="col-xl-4 col-md-6 offset-xl-4 offset-md-3">
                        <RegisterFormPart />
                    </div>
                </div>
            </div>
        </div>
    </AuthLayout>
</template>
<script setup>
    import AuthLayout from '@/layouts/AuthLayout';
    import RegisterFormPart from './Parts/RegisterFormPart';
    import { useAuthStore } from '@/stores/authStore';
    import { onMounted } from 'vue';
    const authStore = useAuthStore();
    onMounted(() => {
        authStore.checkBrand();
    });
</script>
<style>
    .wrapper {
        background-size: cover !important;
        height: 100vh;
    }

    .wrapper::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(41, 46, 64, 0.7);
        z-index: 1;
    }

    .wrapper>* {
        position: relative;
        z-index: 1;
    }
</style>
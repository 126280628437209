import { mainAPI } from './api';

const URLS = {
    bookingCarsUrl: 'helpers/booking/cars/get-formatted-cars',
    bookingCarsByParentmodelUrl: 'helpers/booking/cars/get-cars-by-parentmodel',
    findCarByModelVersionId: 'helpers/booking/cars/find-car-by-model-version-id',
    dealersCentersUrl: 'helpers/dealer-centers',
    managersUrl: 'helpers/booking/managers',
    picklistUrl: 'helpers/picklist',
    testdriveByIdUrl: 'helpers/booking/registrations/get-testdrives-by-id'
};

export const fetchBookingCars = (payload) => {
    return mainAPI.get(URLS.bookingCarsUrl, { params: payload });
};

export const fetchBookingCarsByParentmodel = (payload) => {
    return mainAPI.get(URLS.bookingCarsByParentmodelUrl, { params: payload });
};

export const fetchFindCarByModelVersionId = (payload) => {
    return mainAPI.get(URLS.findCarByModelVersionId, { params: payload });
};

export const fetchDealerCenters = (payload) => {
    return mainAPI.get(URLS.dealersCentersUrl, { params: payload });
};

export const fetchManagers = (payload) => {
    return mainAPI.get(URLS.managersUrl, { params: payload });
};

export const fetchPicklist = (payload) => {
    return mainAPI.get(URLS.picklistUrl, { params: payload });
}

export const fetchTestdriveById = (payload) => {
    return mainAPI.get(URLS.testdriveByIdUrl, { params: payload });
}

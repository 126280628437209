<template>
    <div class="modal fade" id="modal-support" tabindex="-1" aria-labelledby="modal-support-label" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content flat">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-support-label">Служба підтримки</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3 row">
                            <label for="name-support" class="col-sm-2 col-form-label">Ім'я</label>
                            <div class="col-sm-10">
                                <input type="text" class="form-control flat" id="name-support">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="phone-support" class="col-sm-2 col-form-label">Телефон</label>
                            <div class="col-sm-10">
                                <input type="phone" class="form-control flat" id="phone-support">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="email-support" class="col-sm-2 col-form-label">E-mail</label>
                            <div class="col-sm-10">
                                <input type="email" class="form-control flat" id="email-support">
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <label for="description-support" class="col-sm-2 col-form-label">Опис</label>
                            <div class="col-sm-10">
                                <textarea class="form-control" id="description-support" rows="6"></textarea>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-block btn-flat me-1 border-secondary"
                        data-bs-dismiss="modal">Закрити</button>
                    <button type="submit"
                        class="btn btn-primary btn-block btn-flat border-secondary">Відправити</button>
                </div>

            </div>
        </div>
    </div>
</template>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from "vue-router";

export function usePagination() {
    const route = useRoute();
    const router = useRouter();
    const page = ref(route.query?.page ?? 1);
    const total = ref(0);
    const perPage = ref(1);
    const visiblePages = 3;
    const from = ref(0);
    const to = ref(0);

    const setCurrentPage = (cp) => page.value = cp;
    const setTotalPages = (tp) => total.value = tp;
    const setPerPage = (pp) => perPage.value = pp;
    const setFrom = (fromP) => from.value = fromP;
    const setTo = (toP) => to.value = toP;

    const totalPages = computed(() => Math.ceil(total.value / perPage.value));
    const hasPrevPage = computed(() => page.value - 1 >= 1);
    const hasNextPage = computed(() => page.value + 1 <= totalPages.value);

    const prevPage = () => {
        if (hasPrevPage.value) {
            page.value = page.value - 1;
        }
    }

    const nextPage = () => {
        if (hasNextPage.value) {
            page.value = page.value + 1;
        }
    };

    const displayedPages = computed(() => {
        const totalPages = Math.ceil(total.value / perPage.value);
        const currentPage = page.value;

        const pagesToShow = [];

        let startPage = currentPage - Math.floor(visiblePages / 2);
        let endPage = startPage + visiblePages - 1;

        if (startPage < 1) {
            startPage = 1;
            endPage = visiblePages;
        }
        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = endPage - visiblePages + 1;
        }

        for (let i = startPage; i <= endPage; i++) {
            if (i > 0) {
                pagesToShow.push(i);
            }

        }

        return pagesToShow;
    });


    const updateRoutePage = (page) => {
        const currentQuery = { ...route.query };
        currentQuery.page = page;
        router.push({ query: currentQuery });
    };

    return {
        updateRoutePage,
        page, total, perPage, from, to,
        hasPrevPage, hasNextPage,
        setFrom, setTo,
        setCurrentPage, setTotalPages, setPerPage, prevPage, nextPage, displayedPages
    };
}
<template>
    <div class="bg-white rounded-3 p-2 shadow-sm flex-wrap history-wrapper">
        <div class="d-flex justify-content-between align-items-center p-1">
            <span class="text-primary fw-bolder">Історія</span>
            <RoundedPillButtonBase class="bg-white text-muted mb-lg-0 mb-3 p-3  border border-white">
                <span class="pe-2 fw-bolder d-lg-block d-none"><br></span>
            </RoundedPillButtonBase>
        </div>
        <HistoryTabsPart :counters="counters" :setCurrentTab="setCurrentTabHandler" :currentTab="currentTab" />
        <div class="table-responsive ">
            <table class="table table-history">
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Тип</th>
                        <th>Інфо</th>
                        <th>Час на сайті</th>
                    </tr>
                </thead>
                <tbody>

                    <template v-if="currentTab === 'all' || currentTab ==='clientVisits'">
                        <tr v-for="clientVisit in clientVisitsStore.clientVisits" :key="clientVisit['AFSinfoId']">
                            <td>
                                <font-awesome-icon style="font-size: 16px;" icon="fa-regular fa-calendar" />
                                {{ clientVisit['returnDate'] }}
                            </td>
                            <td>
                                Запис на сервіс
                            </td>
                            <td>
                                {{ clientVisit['vin'] }} {{clientVisit['dealer'] }}
                            </td>
                            <td>

                            </td>
                        </tr>
                    </template>

                    <template v-if="currentTab === 'all' || currentTab ==='requestsByViberSms'">
                        <tr v-for="(viberSms, index) in registrationsHistoryStore.registrationsHistory?.viberSms"
                            :key="index">
                            <td>
                                <font-awesome-icon style="font-size: 16px;" icon="fa-regular fa-calendar" />
                                {{ viberSms['createdDate'] }}
                            </td>
                            <td>
                                Viber/Sms
                            </td>
                            <td> {{ viberSms['subject'] }} </td>
                            <td></td>

                        </tr>
                    </template>

                    <template v-if="currentTab === 'all' || currentTab ==='requestsByPhoneCalls'">
                        <tr v-for="(phoneCall, index) in registrationsHistoryStore.registrationsHistory?.phoneCalls"
                            :key="index">
                            <td>
                                <font-awesome-icon style="font-size: 16px;" icon="fa-regular fa-calendar" />
                                {{ phoneCall['createdDate'] }}
                            </td>
                            <td>
                                Дзвінок
                            </td>
                            <td> {{ phoneCall['subject'] }} </td>
                            <td></td>

                        </tr>
                    </template>

                    <template v-if="currentTab === 'all' || currentTab ==='requestsByEmails'">
                        <tr v-for="(email, index) in registrationsHistoryStore.registrationsHistory?.emails"
                            :key="index">
                            <td>
                                <font-awesome-icon style="font-size: 16px;" icon="fa-regular fa-calendar" />
                                {{ email['createdDate'] }}
                            </td>
                            <td>
                                Email
                            </td>
                            <td> {{ email['subject'] }} </td>
                            <td></td>

                        </tr>
                    </template>

                    <template v-if="currentTab === 'all' || currentTab ==='requestsByGas'">
                        <tr v-for="(ga, index) in registrationsHistoryStore.registrationsHistory?.gas" :key="index">
                            <td>
                                <font-awesome-icon style="font-size: 16px;" icon="fa-regular fa-calendar" />
                                {{ ga['receivingDate'] }}
                            </td>
                            <td>
                                GA
                            </td>
                            <td> {{ ga['duration'] }} </td>
                            <td></td>

                        </tr>
                    </template>

                    <template v-if="currentTab === 'all' || currentTab ==='requestsByTel'">
                        <tr v-for="(request, index) in registrationsByPhoneNumberStore.registrationsByPhoneNumber"
                            :key="index">
                            <td>
                                <font-awesome-icon style="font-size: 16px;" icon="fa-regular fa-calendar" />
                                {{ request['createdDate'] }}
                            </td>
                            <td>
                                {{ request['recordtype'] }}
                            </td>
                            <td> {{ request['comment'] }} </td>
                            <td></td>

                        </tr>
                    </template>

                </tbody>
            </table>
        </div>

    </div>
</template>
<script setup>
    import { useAuthStore } from '@/stores/authStore';
    import { useTestdrivesStore } from '@/stores/helpers/testdrivesStore';
    import { useClientVisitsStore } from '@/stores/contracts/clientVisitsStore';
    import { useRegistrationsHistoryStore } from '@/stores/contracts/registrationsHistoryStore';
    import { useRegistrationsByPhoneNumberStore } from '@/stores/contracts/registrationsByPhoneNumberStore';

    import { watch, onMounted, computed, ref } from 'vue';
    import { useRoute } from "vue-router";
    import { clientVisitsHandlerTimeout } from '@/services/contracts/clientVisitsService';
    import { registrationsHistoryHandlerTimeout } from '@/services/contracts/registrationsHistoryService';
    import { registrationsByPhoneNumberHandlerTimeout } from '@/services/contracts/registrationsByPhoneNumberService';

    import RoundedPillButtonBase from '@/components/base/RoundedPillButtonBase';
    import HistoryTabsPart from './HistoryTabsPart';

    const authStore = useAuthStore();
    const testdrivesStore = useTestdrivesStore();
    const clientVisitsStore = useClientVisitsStore();
    const registrationsHistoryStore = useRegistrationsHistoryStore();
    const registrationsByPhoneNumberStore = useRegistrationsByPhoneNumberStore();

    const route = useRoute();

    const currentTab = ref('all');
    const setCurrentTabHandler = (tab) => {
        currentTab.value = tab;
    };

    const updateClientVisits = () => {
        const payload = {
            brand: authStore.brand,
            vin: testdrivesStore.testdrive['New_vinkod']
        };

        if (testdrivesStore.testdrive['New_vinkod']) {
            clientVisitsHandlerTimeout({ clientVisitsStore, payload });
        }
    };

    const updateRegistrationsHistory = () => {
        const payload = {
            brand: authStore.brand,
            id: route.params.tdriveId
        };

        registrationsHistoryHandlerTimeout({ registrationsHistoryStore, payload });
    };

    const updateRegistrationsByPhoneNumber = () => {
        const payload = {
            brand: authStore.brand,
            phone: testdrivesStore.testdrive['MobilePhone']
        };
        if (testdrivesStore.testdrive['MobilePhone']) {
            registrationsByPhoneNumberHandlerTimeout({ registrationsByPhoneNumberStore, payload });
        }
    };

    const handlerBrandChange = () => {
        updateClientVisits();
        updateRegistrationsHistory();
        updateRegistrationsByPhoneNumber();
    };

    const handlerVinChange = () => {
        updateClientVisits();
    };

    const handlerTdriveIdChange = () => {
        updateRegistrationsHistory();
    }

    const handlerPhoneChange = () => {
        updateRegistrationsByPhoneNumber();
    };

    const counters = computed(() => {

        let clientVisitsCounter = clientVisitsStore?.clientVisits?.length ?? 0;
        let requestsByViberSmsCounter = registrationsHistoryStore.registrationsHistory?.viberSms?.length ?? 0;
        let requestsByPhoneCallsCounter = registrationsHistoryStore.registrationsHistory?.phoneCalls?.length ?? 0;
        let requestsByEmailsCounter = registrationsHistoryStore.registrationsHistory?.emails?.length ?? 0;
        let requestsByGasCounter = registrationsHistoryStore.registrationsHistory?.gas?.length ?? 0;
        let requestsByTelCounter = registrationsByPhoneNumberStore.registrationsByPhoneNumber?.length ?? 0;

        let allCounter = clientVisitsCounter + requestsByViberSmsCounter + requestsByPhoneCallsCounter + requestsByEmailsCounter + requestsByGasCounter + requestsByTelCounter;

        return {
            all: allCounter,
            clientVisits: clientVisitsCounter,
            requestsByViberSms: requestsByViberSmsCounter,
            requestsByPhoneCalls: requestsByPhoneCallsCounter,
            requestsByEmails: requestsByEmailsCounter,
            requestsByGas: requestsByGasCounter,
            requestsByTel: requestsByTelCounter
        }

    });

    watch(() => authStore.brand, handlerBrandChange);
    watch(() => testdrivesStore.testdrive['New_vinkod'], handlerVinChange);
    watch(() => route.params.tdriveId, handlerTdriveIdChange);
    watch(() => testdrivesStore.testdrive['MobilePhone'], handlerPhoneChange);

    onMounted(() => {
        updateClientVisits();
        updateRegistrationsHistory();
        updateRegistrationsByPhoneNumber();
    });
</script>

<style scoped>
    .history-wrapper {
        min-height: 30vh;
    }

    .table-history th {
        color: grey;
    }

    .table-history td {
        vertical-align: middle;
        min-width: 130px;
        height: 100px;
        padding: 10px;
        --bs-text-opacity: 1;
        color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
    }
</style>
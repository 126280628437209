<template>
    <div class="section-all-brand" :style="{
        'background':`url(${getBrandImg(currentBrand)})`+'center center no-repeat'}">

        <div class="brand-item brand-item-1" @mouseover.prevent="currentBrand=CITROEN">
            <div class="brand-item__wrapper">
                <div class="brand_img">
                    <figure class="chart" data-percent="100">
                        <svg height="204" width="204">
                            <circle cx="102" cy="102" r="100" />
                        </svg>
                    </figure>
                    <img src="@/assets/img/brands/brand.png" width="118" height="144" />
                </div>
                <h2>Brand 1</h2>
                <router-link :to="{name:'auth.login', params:{brand:CITROEN}}">Вхід</router-link>
            </div>
        </div>
        <!-- peugeot -->
        <div class="brand-item brand-item-2" @mouseover.prevent="currentBrand=PEUGEOT">
            <div class="brand-item__wrapper">
                <div class="brand_img">
                    <figure class="chart" data-percent="100">
                        <svg height="204" width="204">
                            <circle cx="102" cy="102" r="100" />
                        </svg>
                    </figure>
                    <img src="@/assets/img/brands/brand.png" width="118" height="144" />
                </div>
                <h2>Brand 1</h2>
                <router-link :to="{name:'auth.login', params:{brand:PEUGEOT}}">Вхід</router-link>
            </div>
        </div>
        <!-- opel-->
        <div class="brand-item brand-item-3" @mouseover.prevent="currentBrand=OPEL">
            <div class="brand-item__wrapper">
                <div class="brand_img">
                    <figure class="chart" data-percent="100">
                        <svg height="204" width="204">
                            <circle cx="102" cy="102" r="100" />
                        </svg>
                    </figure>
                    <img src="@/assets/img/brands/brand.png" width="118" height="144" />
                </div>
                <h2>Brand 3</h2>
                <router-link :to="{name:'auth.login', params:{brand:OPEL}}">Вхід</router-link>
            </div>
        </div>
        <!-- ds-->
        <div class="brand-item brand-item-4" @mouseover.prevent="currentBrand=DS_AUTOMOBILES">
            <div class="brand-item__wrapper">
                <div class="brand_img">
                    <figure class="chart" data-percent="100">
                        <svg height="204" width="204">
                            <circle cx="102" cy="102" r="100" />
                        </svg>
                    </figure>
                    <img src="@/assets/img/brands/brand.png" width="118" height="144" />
                </div>
                <h2>Brand 3</h2>
                <router-link :to="{name:'auth.login', params:{brand:DS_AUTOMOBILES}}">Вхід</router-link>
            </div>
        </div>
        <!-- jeep-->
        <div class="brand-item brand-item-5" @mouseover.prevent="currentBrand=JEEP">
            <div class="brand-item__wrapper">
                <div class="brand_img">
                    <figure class="chart" data-percent="100">
                        <svg height="204" width="204">
                            <circle cx="102" cy="102" r="100" />
                        </svg>
                    </figure>
                    <img src="@/assets/img/brands/brand.png" width="118" height="144" />
                </div>
                <h2>Brand 4</h2>
                <router-link :to="{name:'auth.login', params:{brand:JEEP}}">Вхід</router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { CITROEN, PEUGEOT, OPEL, DS_AUTOMOBILES, JEEP } from '@/constants/availableBrands';
    import { getBrandImg } from '@/services/brandLogoService';
    import { ref } from 'vue';
    const currentBrand = ref(PEUGEOT);
</script>

<style>
    @import '@/assets/css/auth/brand-select.css';
</style>
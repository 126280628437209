<template>
    <div class="accordion accordion-flush mx-3 " id="accordionFlushExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed bg-primary text-white rounded-3" type="button"
                    data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                    aria-controls="flush-collapseOne">
                    <font-awesome-icon style="font-size: 16px;" icon="fa-solid fa-bars" /> <span
                        class="mx-2">Меню</span>
                </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    <nav class="nav flex-column p-0 m-0">
                        <a class="nav-link" href="#">Пункт 1</a>
                        <a class="nav-link" href="#">Пункт 2</a>
                        <a @click="toggleNested('about-dealer')"
                            class="btn btn-link d-flex justify-content-between nav-link" aria-current="page"
                            href="#">Пункт 3
                            <font-awesome-icon style="font-size: 16px;" :icon="togglerIcon('about-dealer')" />
                        </a>
                        <nav class="nav flex-column ms-3" v-if="isNestedOpen('about-dealer')">
                            <a class="nav-link active" href="#">Пункт 3.1</a>
                            <a class="nav-link" href="#">Пункт 3.2</a>
                            <a class="nav-link" href="#">Пункт 3.3</a>
                            <a class="nav-link" href="#">Пункт 3.4</a>
                        </nav>
                        <a class="nav-link" href="#">Пункт 4</a>
                        <a class="nav-link" href="#">Пункт 5</a>

                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { reactive } from 'vue';

    const menuOpenStates = reactive({
        'about-dealer': false,
        'private-leads': false,
        '2b2': false,
        'finances': false,
        'incidents': false
    });

    const toggleNested = (key) => {
        menuOpenStates[key] = !menuOpenStates[key];
    };

    const isNestedOpen = (key) => {
        return menuOpenStates[key];
    };

    const togglerIcon = (key) => {
        if (isNestedOpen(key)) {
            return 'fa-solid fa-chevron-up';
        }
        return 'fa-solid fa-chevron-down';
    }
</script>
<template>
    <div class="d-flex justify-content-center flex-wrap my-3 px-3" style="max-width: 22rem; width: 22rem;">
        <div @click.prevent="authStore.setBrand(CITROEN)"
            class="rounded-circle d-flex justify-content-center align-items-center border bg-light  m-1 brand-circle"
            :class="{'border-success':authStore.brand==CITROEN}">
            <img src="@/assets/img/brands/brand-primary.png" class="img-fluid p-3">
        </div>
        <div @click.prevent="authStore.setBrand(PEUGEOT)"
            class="rounded-circle d-flex justify-content-center align-items-center border bg-light m-1 brand-circle"
            :class="{'border-success':authStore.brand==PEUGEOT}">
            <img src="@/assets/img/brands/brand-primary.png" class="img-fluid p-3">
        </div>
        <div @click.prevent="authStore.setBrand(OPEL)"
            class="rounded-circle d-flex justify-content-center align-items-center border bg-light m-1 brand-circle"
            :class="{'border-success':authStore.brand==OPEL}">
            <img src="@/assets/img/brands/brand-primary.png" class="img-fluid p-3">
        </div>
        <div @click.prevent="authStore.setBrand(DS_AUTOMOBILES)"
            class="rounded-circle d-flex justify-content-center align-items-center border bg-light m-1 brand-circle"
            :class="{'border-success':authStore.brand==DS_AUTOMOBILES}">
            <img src="@/assets/img/brands/brand-primary.png" class="img-fluid p-3 ">
        </div>
        <div @click.prevent="authStore.setBrand(JEEP)"
            class="rounded-circle d-flex justify-content-center align-items-center border  bg-light m-1 brand-circle"
            :class="{'border-success':authStore.brand==JEEP}">
            <img src="@/assets/img/brands/brand-primary.png" class="img-fluid p-3">
        </div>
    </div>
</template>
<script setup>
    import { useAuthStore } from '@/stores/authStore';
    import { watchEffect } from 'vue';
    import { useRoute } from "vue-router";
    import { CITROEN, PEUGEOT, OPEL, DS_AUTOMOBILES, JEEP } from '@/constants/availableBrands';
    const authStore = useAuthStore();
    const route = useRoute();

    watchEffect(() => route.params.brand, authStore.checkBrand());
</script>
<style>
    .brand-circle {
        width: 6rem;
        height: 6rem;
    }

    .brand-circle:hover {
        border-color: var(--bs-green) !important;
        cursor: pointer;
    }
</style>
<template>
    <div class="bg-white rounded-3 p-2 shadow-sm flex-wrap comments-wrapper">
        <div class="d-flex justify-content-between align-items-center p-1">
            <span class="text-primary fw-bolder">Комментарі</span>
            <RoundedPillButtonBase class="bg-white text-muted mb-lg-0 mb-3 p-3  border border-white">
                <span class="pe-2 fw-bolder d-lg-block d-none"><br></span>
            </RoundedPillButtonBase>
        </div>
        <ScrollYBase class="d-flex flex-column">
            <div v-for="comment in commentsStore.comments" :key="comment.id" class="mb-5">
                <div class="d-flex justify-content-between text-primary align-items-center">
                    <div class="d-flex justify-content-start align-items-center">
                        <button class="rounded-circle btn btn-link btn-block  text-primary">
                            <font-awesome-icon style="font-size: 30px;" icon="fa-solid fa-circle-user" />
                        </button>
                        <div>
                            {{ comment.user.fullName }}
                        </div>
                    </div>
                    <div class="me-2">{{ comment.createdAt }}</div>
                </div>
                <div class="d-flex justify-content-between text-primary align-items-center">
                    <div class="ms-5 p-2 bg-light ">
                        {{ comment.comment }}
                    </div>
                </div>
            </div>
        </ScrollYBase>
        <hr>
        <div class="row">
            <div class="col-10">
                <input type="text" v-model="comment" class="form-control comment-text text-primary"
                    :class="{'is-invalid':commentsStore.statusIsError}" id="enter-comment"
                    placeholder="Напишіть ваш коментар...">
                <div v-if="commentsStore.mainError" class="invalid-feedback">
                    {{ commentsStore.mainError }}
                </div>
            </div>
            <div class="col-2 text-end">
                <button class="btn btn-link" @click="createCommentHandler">
                    <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-paper-plane" />
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
    import RoundedPillButtonBase from '@/components/base/RoundedPillButtonBase';
    import ScrollYBase from '@/components/base/ScrollYBase';
    import { useAuthStore } from '@/stores/authStore';
    import { useCommentsStore } from '@/stores/contracts/commentsStore';
    import { useRoute } from "vue-router";
    import { commentsHandlerTimeout, commentCreateHandlerTimeout } from '@/services/contracts/commentsService';
    import { watch, onMounted, ref } from 'vue';

    const authStore = useAuthStore();
    const commentsStore = useCommentsStore();
    const route = useRoute();

    const updateComments = () => {
        const payload = {
            brand: authStore.brand,
            tdriveId: route.params.tdriveId
        };
        commentsHandlerTimeout({ commentsStore, payload });
    };

    const handlerBrandChange = () => {
        updateComments();
    };

    const handleTdriveIdchange = () => {
        updateComments();
    };

    const comment = ref(null);

    const createCommentHandler = () => {
        const payload = {
            brand: authStore.brand,
            tdriveId: route.params.tdriveId,
            webUserId: authStore.user['New_web_userId'],
            comment: comment.value
        };
        commentCreateHandlerTimeout({ commentsStore, payload });
        comment.value = '';
    };

    watch(() => authStore.brand, handlerBrandChange, { deep: true });
    watch(() => route.params.tdriveId, handleTdriveIdchange, { deep: true });

    onMounted(() => {
        updateComments();
    });
</script>
<style scoped>
    .comments-wrapper {
        min-height: 30vh;
    }

    .comment-text {
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    .comment-text::placeholder {
        --bs-text-opacity: 1;
        color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
    }
</style>
<template>
    <div class="bg-white rounded-3 p-2 shadow-sm flex-wrap planning-wrapper">
        <div class="d-flex justify-content-between align-items-center p-1 ">
            <span class="text-primary fw-bolder ">Планування</span>
            <RoundedPillButtonBase class="bg-white text-primary mb-lg-0 mb-3 p-3 shadow-sm border">
                <span class="pe-2 fw-bolder d-lg-block d-none">Запланувати наступну задачу</span>
                <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-arrow-right" />
            </RoundedPillButtonBase>
        </div>
        <div class="alert alert-danger" role="alert" v-if="planningTasksStore.statusIsError">
            {{ planningTasksStore.mainError }}
        </div>
        <LoaderBase class="p-1 text-primary" v-if="planningTasksStore.statusIsPending" />
        <div class="table-responsive" v-if="planningTasksStore.statusIsSuccess && planningTasksStore.tasks != {}">
            <ScrollYBase>
                <table class="table table-planning mb-0">
                    <thead>
                        <tr>
                            <th>Дата і час</th>
                            <th>Тип дії</th>
                            <th>Коментар</th>
                            <th class="text-end">Дія</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="task in planningTasksStore.tasks" :key="task.id">
                            <td>
                                <font-awesome-icon style="font-size: 16px;" icon="fa-regular fa-calendar" />
                                {{ task.date }}
                            </td>
                            <td>{{ task.type }}</td>
                            <td>
                                <div v-html="task.comment"></div>
                            </td>
                            <td class="text-end">
                                <div class="d-flex flex-column justify-content-between align-items-end text-primary">
                                    <div class="btn btn-sm btn-link border">
                                        <font-awesome-icon style="font-size: 16px;" icon="fa-solid fa-xmark" />
                                    </div>
                                    <div class="btn btn-sm btn-link border mt-1 ">
                                        <font-awesome-icon style="font-size: 15px;" icon="fa-solid fa-check" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ScrollYBase>
        </div>
    </div>
</template>
<script setup>
    import { useAuthStore } from '@/stores/authStore';
    import { planningTasksHandlerTimeout } from '@/services/contracts/planningTasksService';
    import { usePlanningTasksStore } from '@/stores/contracts/planningTasksStore';
    import { onMounted, watch } from 'vue';
    import { useRoute } from "vue-router";
    import RoundedPillButtonBase from '@/components/base/RoundedPillButtonBase';
    import LoaderBase from '@/components/base/LoaderBase';
    import ScrollYBase from '@/components/base/ScrollYBase';

    const authStore = useAuthStore();
    const planningTasksStore = usePlanningTasksStore();
    const route = useRoute();

    const updatePlanningTasks = () => {
        let payload = {
            brand: authStore.brand,
            id: route.params.tdriveId
        };
        planningTasksHandlerTimeout({ planningTasksStore, payload });
    };

    watch(() => authStore.brand, updatePlanningTasks, { deep: true });
    watch(() => route.params.tdriveId, updatePlanningTasks, { deep: true });

    onMounted(() => {
        updatePlanningTasks();
    });
</script>
<style scoped>
    .scroll-wrapper {
        height: 15vh !important;
    }

    .planning-wrapper {
        min-height: 25.3vh;
    }

    .table-planning th {
        color: grey;
    }

    .table-planning td {
        vertical-align: middle;
        width: 50px;
        height: 100px;
        padding: 10px;
        --bs-text-opacity: 1;
        color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
    }
</style>
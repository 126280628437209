import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { reactive } from 'vue';

export const useClientContactsStore = defineStore('contracts.leadCard.clientContacts', () => {
    const formStatus = useFormStatus();
    const clientContacts = reactive({});

    return {
        ...formStatus,
        clientContacts
    };
})
import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { ref } from 'vue';

export const useTestdrivesStore = defineStore('helpers.booking.registrations.testdrives', () => {
    const formStatus = useFormStatus();
    const testdrive = ref({});

    return {
        ...formStatus,
        testdrive
    };
})
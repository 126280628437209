export const withAsync = async (fn, ...args) => {
    try {
        const response = await fn(...args);
        return {
            statusCode: 200,
            response: response ? response : null,
            error: null,
            validationErrors: null
        };
    } catch (error) {
        return {
            statusCode: error.response?.status,
            response: null,
            error: error.response?.status === 422
                ? error.response.data.message
                : error.message,
            validationErrors: (error.response?.status === 422 && error.response.data?.errors) ? error.response.data.errors : null
        };
    }
};
import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchManagers } from "@/api/helpersApi";

export const managersHandlerTimeout = ({ managersStore, payload }) => {
    setTimeout(() => managersHandlerDebounced({ managersStore, payload }), 300);
};

const managersHandlerDebounced = debounce(async function ({ managersStore, payload }) {
    managersStore.setStatusPending();
    managersStore.setMainError(null);

    const { response, error } = await withAsync(fetchManagers, payload);

    if (response) {
        managersStore.setStatusSuccess();
        managersStore.managers = response.data;
    } else if (error) {
        managersStore.setStatusError();
        managersStore.setMainError(error);
    }
}, 300);





<template>
    <AuthLayout>
        <div class="wrapper" :style="{ 'background': `url(${brandImg})`+'no-repeat scroll center center' }">
            <div>
                <div class="row">
                    <div class="col-12 text-center mt-5">
                        <p class="text-white fs-1">
                            <b>PORTAL</b>
                        </p>
                    </div>
                    <div class="col-xl-4 col-md-6 offset-xl-4 offset-md-3">
                        <LoginFormPart />
                    </div>
                </div>
            </div>
        </div>
        <ModalSupportPart />
    </AuthLayout>
</template>
<script setup>
    import AuthLayout from '@/layouts/AuthLayout';
    import LoginFormPart from './Parts/LoginFormPart';
    import ModalSupportPart from './Parts/ModalSupportPart';
    import { computed, onMounted } from 'vue';
    import { getBrandImg } from '@/services/brandLogoService';
    import { useAuthStore } from '@/stores/authStore';

    const authStore = useAuthStore();
    const brandImg = computed(() => getBrandImg(authStore.brand));

    onMounted(() => {
        authStore.checkBrand();
    });

</script>
<style>
    .wrapper {
        background-size: cover !important;
        margin: 0;
    }

    .wrapper::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(41, 46, 64, 0.7);
        z-index: 1;
    }

    .wrapper>* {
        position: relative;
        z-index: 1;
    }
</style>

import { ref, computed } from 'vue';
import { apiStatuses } from '@/constants/apiStatuses';
export function useFormStatus() {
    const status = ref(apiStatuses.IDLE);
    const mainError = ref(null);
    const validationErrors = ref(null);

    function setMainError(mError) {
        mainError.value = mError;
    }

    function setValidationErrors(vErrors) {
        validationErrors.value = vErrors;
    }

    function setStatusIDLE() {
        status.value = apiStatuses.IDLE;
    }

    function setStatusPending() {
        status.value = apiStatuses.PENDING;
    }

    function setStatusSuccess() {
        status.value = apiStatuses.SUCCESS;
    }

    function setStatusValidationError() {
        status.value = apiStatuses.VALIDATION_ERROR;
    }

    function setStatusError() {
        status.value = apiStatuses.ERROR;
    }

    const statusIsIDLE = computed(() => status.value === apiStatuses.IDLE);
    const statusIsPending = computed(() => status.value === apiStatuses.PENDING);
    const statusIsSuccess = computed(() => status.value === apiStatuses.SUCCESS);
    const statusIsValidationError = computed(() => status.value === apiStatuses.VALIDATION_ERROR);
    const statusIsError = computed(() => status.value === apiStatuses.ERROR);

    return {
        mainError, validationErrors,
        statusIsIDLE, statusIsPending, statusIsSuccess, statusIsValidationError, statusIsError,
        setMainError, setValidationErrors,
        setStatusIDLE, setStatusPending, setStatusSuccess, setStatusValidationError, setStatusError
    };
}
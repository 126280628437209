import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { ref } from 'vue';

export const useFlowChannelsStore = defineStore('helpers.flowChannels', () => {
    const formStatus = useFormStatus();
    const flowChannels = ref([]);

    return {
        ...formStatus,
        flowChannels
    };
})
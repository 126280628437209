<template>
    <select class="form-select" :multiple="multiple" ref="selectElement" :id="id" :name="name" :disabled="disabled"
        :required="required"></select>
</template>

<script setup>
    import $ from 'jquery';
    import 'select2/dist/js/select2.full.min.js';
    import 'select2/dist/css/select2.min.css';
    import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';

    import { onMounted, defineProps, ref, watch, toRefs, defineEmits } from 'vue';

    const selectElement = ref(null);
    const emit = defineEmits(['update:modelValue', 'select']);
    const select2 = ref(null);

    const props = defineProps({
        modelValue: [String, Number, Array],
        id: {
            type: String,
            default: 'select2-element',
        },
        name: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Select your choise',
        },
        options: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        invalid: {
            type: Boolean,
            default: false,
        },
        settings: {
            type: Object,
            default: () => { },
        },
    });

    const {
        id,
        name,
        disabled,
        required,
        placeholder,
        invalid,
        options,
        multiple,
        settings,
        modelValue,
    } = toRefs(props);

    const setValue = (val) => {
        if (Array.isArray(val)) {
            select2.value?.val([...val]);
        } else {
            select2.value?.val([val]);
        }
        select2.value?.trigger('change');
        setInvalid();
    };

    const setOption = (val = []) => {
        select2.value?.empty();
        select2.value?.select2({
            theme: 'bootstrap-5',
            placeholder: placeholder.value,
            ...settings.value,
            data: val,
        });
        setValue(modelValue.value);
    };

    const setInvalid = () => {
        if (invalid.value) {
            $(`#${id.value}`).addClass('is-invalid');
        } else {
            if ($(`#${id.value}`).hasClass('is-invalid')) {
                $(`#${id.value}`).removeClass('is-invalid');
            }
        }
    };

    onMounted(() => {
        select2.value = $(selectElement.value).select2({
            theme: 'bootstrap-5',
            placeholder: placeholder.value,
            ...settings.value,
            data: options.value,
        });

        select2.value.on('select2:select select2:unselect', (ev) => {
            emit('update:modelValue', select2.value.val());
            emit('select', ev.params.data);
        });

        setValue(modelValue.value);
    });

    watch(
        options,
        (val) => {
            setOption(val);
        },
        { deep: true }
    );

    watch(
        modelValue,
        (val) => {
            setValue(val);
        },
        { deep: true }
    );

    watch(
        invalid,
        () => {
            setInvalid();
        },
        { deep: true }
    );
</script>
<style type="text/scss">
    .input-group > .select2-container--bootstrap {
  width: auto;
  flex: 1 1 auto;
}

.input-group > .select2-container--bootstrap .select2-selection--single {
  height: 100%;
  line-height: inherit;
  padding: 0.5rem 1rem;
}
.selection .is-ivalid {
  border-color: var(--bs-form-invalid-border-color) !important;
}

.select2-selection__placeholder {
  color: var(--bs-gray) !important;
  font-family: var(--bs-font-sans-serif) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

.select2-container--disabled .select2-selection * {
  background-color: var(--bs-body-bg) !important;
  cursor: pointer;
}

.select2-container--disabled .select2-selection,
.select2-container--disabled.select2-container--focus.select2-selection,
.select2-container--disabled.select2-selection.select2-selection--single.select2-container--disabled.select2-selection__placeholder {
  background-color: var(--bs-gray-200) !important;
  cursor: pointer;
}

.select2-container--bootstrap-5.select2-container--disabled .select2-selection,
.select2-container--bootstrap-5.select2-container--disabled.select2-container--focus
  .select2-selection {
  cursor: pointer;
}

.select2-selection {
  border-left: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.select2-container--bootstrap-5 .select2-results__options {
  max-height: 200px; 
  overflow-y: auto;
  scrollbar-width: thin; 
  scrollbar-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) #f0f0f0; 
}

.select2-container--bootstrap-5 .select2-results__options::-webkit-scrollbar {
  width: 5px;
  padding-right: 5px !important;
}

.select2-container--bootstrap-5
  .select2-results__options::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary) !important;
  border-radius: 5px;
}

.select2-container--bootstrap-5
  .select2-results__options::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted){
    background-color: var(--bs-primary) !important;
}
</style>
<template>
    <div class="bg-light p-1 m-1 d-flex  justify-content-center justify-content-lg-between flex-wrap min-w-60"
        :class="contractTabsClasses">
        <div class="d-flex  justify-content-center flex-wrap">
            <div class="border border-1 border-light rounded-circle circle-icon d-flex justify-content-center align-items-center m-2"
                :class="redContractsTabClasess" @click.prevent="contractsStore.setCurrentTab(RED_CONTRACTS_TAB)">
                <font-awesome-icon style="font-size: 20px" icon="fa-solid fa-fire" />
            </div>

            <div class="border border-1 border-light rounded-pill d-flex justify-content-between align-items-center p-1 m-1"
                :class="inWorkContractsTabClasess" @click.prevent="contractsStore.setCurrentTab(IN_WORK_CONTRACTS_TAB)">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ contractsStore.tabCounters[IN_WORK_CONTRACTS_TAB]??0 }}</b>
                </div>
                <div class="px-2">В роботі</div>
            </div>

            <div class="rounded-pill  border border-1 border-light d-flex justify-content-between align-items-center p-1 m-1 "
                :class="sellContractsTabClasess" @click.prevent="contractsStore.setCurrentTab(SELL_CONTRACTS_TAB)">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ contractsStore.tabCounters[SELL_CONTRACTS_TAB]??0 }}</b>
                </div>
                <div class="px-2">Продано</div>
            </div>

            <div class="rounded-pill border border-1 border-light d-flex justify-content-between align-items-center p-1 m-1"
                :class="cancelContractsTabClasess" @click.prevent="contractsStore.setCurrentTab(CANCEL_CONTRACTS_TAB)">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ contractsStore.tabCounters[CANCEL_CONTRACTS_TAB]??0 }}</b>
                </div>
                <div class="px-2">Відмінено</div>
            </div>

            <div class="rounded-pill border border-1 border-light d-flex justify-content-between align-items-center p-1 m-1"
                :class="allContractsTabClasess" @click.prevent="contractsStore.setCurrentTab(ALL_CONTRACTS_TAB)">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ contractsStore.tabCounters[ALL_CONTRACTS_TAB]??0 }}</b>
                </div>
                <div class="px-2">Всі</div>
            </div>

        </div>

        <div @click.prevent="exportContractsHandler"
            class="border-primary border border-2 rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center m-2 cursor-pointer">
            <font-awesome-icon style="font-size: 20px" icon="fa-solid fa-download" />
        </div>

    </div>
</template>
<script setup>
    import { computed, watch } from 'vue';
    import { useAuthStore } from '@/stores/authStore';
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { RED_CONTRACTS_TAB, IN_WORK_CONTRACTS_TAB, SELL_CONTRACTS_TAB, CANCEL_CONTRACTS_TAB, ALL_CONTRACTS_TAB } from '@/constants/contractTabTypes';
    import { useGrid } from 'vue-screen';
    import { searchContractsHandlerTimeout, exportContractsHandlerTimeout } from '@/services/contracts/searchContractsService';

    const grid = useGrid('bootstrap5');
    const authStore = useAuthStore();
    const contractsStore = useContractsStore();
    const redContractsTabClasess = computed(() => contractsStore.currentTabIsRedContractsTab ? 'bg-primary text-white' : 'bg-white text-danger contracts-tab');
    const inWorkContractsTabClasess = computed(() => contractsStore.currentTabIsInWorkContractsTab ? 'bg-primary text-white' : 'bg-light text-primary contracts-tab');
    const sellContractsTabClasess = computed(() => contractsStore.currentTabIsSellContractsTab ? 'bg-primary text-white' : 'bg-light text-primary contracts-tab');
    const cancelContractsTabClasess = computed(() => contractsStore.currentTabIsCancelContractsTab ? 'bg-primary text-white' : 'bg-light text-primary contracts-tab');
    const allContractsTabClasess = computed(() => contractsStore.currentTabIsAllContractsTab ? 'bg-primary text-white' : 'bg-light text-primary contracts-tab');
    const contractTabsClasses = computed(() => ['xxl', 'lg'].includes(grid.breakpoint) ? 'rounded-pill' : 'rounded');

    const exportContractsHandler = () => {
        exportContractsHandlerTimeout({ authStore, contractsStore });
    };

    const handleTabChange = () => {
        contractsStore.page = 1;
        searchContractsHandlerTimeout({ authStore, contractsStore });
    }

    watch(() => contractsStore.currentTab, handleTabChange);

</script>
<style>
    .contracts-tab:hover {
        border-color: var(--bs-primary) !important;
        cursor: pointer;
    }
</style>
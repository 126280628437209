import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchPicklist } from "@/api/helpersApi";

export const logisticStatusesHandlerTimeout = ({ logisticStatusesStore, payload }) => {
    setTimeout(() => logisticStatusesHandlerDebounced({ logisticStatusesStore, payload }), 300);
};

const logisticStatusesHandlerDebounced = debounce(async function ({ logisticStatusesStore, payload }) {
    logisticStatusesStore.setStatusPending();
    logisticStatusesStore.setMainError(null);

    const { response, error } = await withAsync(fetchPicklist, payload);

    if (response) {
        logisticStatusesStore.setStatusSuccess();
        logisticStatusesStore.logisticStatuses = response.data;
    } else if (error) {
        logisticStatusesStore.setStatusError();
        logisticStatusesStore.setMainError(error);
    }
}, 300);





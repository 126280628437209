<template>
    <div class="d-flex justify-content-center cursor-pointer">
        <div class="sort-label">{{ props.label }}</div>
        <div class="d-flex justify-content-center align-items-center ms-1">
            <font-awesome-icon style="font-size: 8px;" icon="fas fa-arrow-down"
                :class="{'text-danger':isCurrentASC, 'text-primary':!isCurrentASC}" />
            <font-awesome-icon style="font-size: 8px;" icon="fas fa-arrow-up"
                :class="{'text-danger':isCurrentDESC, 'text-primary':!isCurrentDESC}" />
        </div>
    </div>
</template>

<script setup>
    import { defineProps, computed } from 'vue';

    const props = defineProps({
        label: {
            type: String,
            required: true,
        },
        currentSortColumn: {
            type: String,
            required: true,
        },
        sortColumn: {
            type: String,
            required: true,
        },
        sortType: {
            type: String,
            required: true,
            validator(value) {
                return ['ASC', 'DESC'].includes(value);
            },
        },
    });

    const isCurrentASC = computed(() => {
        return props.currentSortColumn === props.sortColumn && props.sortType === 'ASC';
    });

    const isCurrentDESC = computed(() => {
        return props.currentSortColumn === props.sortColumn && props.sortType === 'DESC';
    });

</script>
<style>
    .sort-label {
        width: 63px !important;
        white-space: normal !important;
        word-wrap: break-word !important;
        font-size: 12px !important;
    }
</style>
import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { reactive } from 'vue';

export const useCommentsStore = defineStore('contracts.leadCard.comments', () => {
    const formStatus = useFormStatus();
    const comments = reactive({});

    return {
        ...formStatus,
        comments
    };
})
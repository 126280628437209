import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchBookingCars, fetchBookingCarsByParentmodel } from "@/api/helpersApi";

export const bookingCarsHandlerTimeout = ({ bookingCarsStore, payload }) => {
    setTimeout(() => bookingCarsHandlerDebounced({ bookingCarsStore, payload }), 300);
};

export const bookingCarsByParentModelHandlerTimeout = ({ bookingCarsStore, payload }) => {
    setTimeout(() => bookingCarsByParentModelHandlerDebounced({ bookingCarsStore, payload }), 300);
};

const bookingCarsByParentModelHandlerDebounced = debounce(async function ({ bookingCarsStore, payload }) {
    bookingCarsStore.setStatusPending();
    bookingCarsStore.setMainError(null);

    const { response, error } = await withAsync(fetchBookingCarsByParentmodel, payload);

    if (response) {
        bookingCarsStore.setStatusSuccess();
        bookingCarsStore.bookingCars = response.data.data;
    } else if (error) {
        bookingCarsStore.setStatusError();
        bookingCarsStore.setMainError(error);
    }
}, 300);

const bookingCarsHandlerDebounced = debounce(async function ({ bookingCarsStore, payload }) {
    bookingCarsStore.setStatusPending();
    bookingCarsStore.setMainError(null);

    const { response, error } = await withAsync(fetchBookingCars, payload);

    if (response) {
        bookingCarsStore.setStatusSuccess();
        bookingCarsStore.bookingCars = response.data.data;
    } else if (error) {
        bookingCarsStore.setStatusError();
        bookingCarsStore.setMainError(error);
    }
}, 300);
<template>
    <hr>
    <div class="form-check" v-for="method in methods" :key="method.id">
        <input v-model="contractsStore.filters.redemptionMethodFilter" class="form-check-input" type="checkbox"
            :value="method.id" :id="'method-'+method.id">
        <label class="form-check-label" :for="'method-'+method.id">
            {{ method.text }}
        </label>
    </div>
</template>
<script setup>
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { ref } from 'vue';
    const contractsStore = useContractsStore();
    const methods = ref([
        { id: "100000000", text: "Рахунок" },
        { id: "100000001", text: "БГ" }
    ]);

</script>
import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchSearchContracts, fetchExportContracts } from "@/api/contractsApi";
import { saveAs } from 'file-saver';

export const exportContractsHandlerTimeout = ({ authStore, contractsStore }) => {
    const payload = getPayload({ authStore, contractsStore });
    setTimeout(() => exportContractsHandlerDebounced({ contractsStore, payload }), 300);
};

export const searchContractsHandlerTimeout = ({ authStore, contractsStore }) => {
    const payload = getPayload({ authStore, contractsStore });
    setTimeout(() => searchContractsHandlerDebounced({ contractsStore, payload }), 300);
};

const searchContractsHandlerDebounced = debounce(async function ({ contractsStore, payload }) {
    contractsStore.setStatusPending();
    contractsStore.setMainError(null);

    const { response, error } = await withAsync(fetchSearchContracts, payload);

    if (response) {
        contractsStore.setStatusSuccess();
        contractsStore.contracts = response.data['data'];
        contractsStore.tabCounters = response.data['tab-counters'];
        contractsStore.setCurrentPage(response.data.current_page);
        contractsStore.setTotalPages(response.data.total);
        contractsStore.setPerPage(response.data.per_page);
        contractsStore.setFrom(response.data.from);
        contractsStore.setTo(response.data.to);

    } else if (error) {
        contractsStore.setStatusError();
        contractsStore.setMainError(error);
    }
}, 300);

const exportContractsHandlerDebounced = debounce(async function ({ contractsStore, payload }) {
    const { response, error } = await withAsync(fetchExportContracts, payload);
    if (response) {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'exported-contracts.xlsx');
    } else if (error) {
        contractsStore.setStatusError();
        contractsStore.setMainError(error);
    }
}, 300);

const getPayload = ({ authStore, contractsStore }) => {
    const payload = {
        brand: authStore.brand,
        page: contractsStore.page,
        limit: contractsStore.perPage,
    };

    if (contractsStore.sort.column) {
        payload.sortColumn = contractsStore.sort.column;
        payload.sortType = contractsStore.sort.type;
    }

    if (contractsStore.search) {
        payload.search = contractsStore.search;
    }

    if (contractsStore.currentTab) {
        payload.contractTabType = contractsStore.currentTab;
    }

    if (contractsStore.filters.periodFilterStartDate) {
        payload.periodFilterDateType = contractsStore.filters.periodFilterDateType;
        payload.periodFilterStartDate = contractsStore.filters.periodFilterStartDate;
    }

    if (contractsStore.filters.periodFilterEndDate) {
        payload.periodFilterDateType = contractsStore.filters.periodFilterDateType;
        payload.periodFilterEndDate = contractsStore.filters.periodFilterEndDate;
    }

    if (contractsStore.filters.leadType) {
        payload.leadType = contractsStore.filters.leadType;
    }

    if (contractsStore.filters.modelFilter) {
        payload.modelFilter = contractsStore.filters.modelFilter;
    }

    if (contractsStore.filters.statusContractFilter) {
        payload.statusContractFilter = contractsStore.filters.statusContractFilter;
    }

    if (contractsStore.filters.redemptionMethodFilter) {
        payload.redemptionMethodFilter = contractsStore.filters.redemptionMethodFilter;
    }

    if (contractsStore.filters.dealerFilter) {
        payload.dealerFilter = contractsStore.filters.dealerFilter;
    }
    return payload;
};

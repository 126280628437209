<template>
    <div class="bg-white p-4 m-3">
        <div v-if="registerStore.mainError" class="alert alert-danger flat" role="alert">{{ registerStore.mainError }}
        </div>
        <form class="bg-white">
            <div class="mb-3">
                <div class="input-group">
                    <input type="text" v-model="registerForm.lastName"
                        :class="{'is-invalid':registerStore.validationErrors?.last_name}" class="form-control flat"
                        id="last_name" placeholder="Введіть прізвище" autocomplete="family-name">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 16px;" icon="fa-solid fa-user" />
                    </span>
                    <div v-if="registerStore.validationErrors?.last_name" class="invalid-feedback">
                        {{ registerStore.validationErrors?.last_name[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input type="text" v-model="registerForm.firstName"
                        :class="{'is-invalid':registerStore.validationErrors?.first_name}" class="form-control flat"
                        id="first_name" placeholder="Введіть ім'я" autocomplete="name">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 16px;" icon="fa-solid fa-user" />
                    </span>
                    <div v-if="registerStore.validationErrors?.first_name" class="invalid-feedback">
                        {{ registerStore.validationErrors?.first_name[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input type="email" v-model="registerForm.email"
                        :class="{'is-invalid':registerStore.validationErrors?.email}" class="form-control flat"
                        id="email" placeholder="Введіть E-mail" autocomplete="email">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 15px;" icon="fa-solid fa-envelope" />
                    </span>
                    <div v-if="registerStore.validationErrors?.email" class="invalid-feedback">
                        {{ registerStore.validationErrors?.email[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input type="text" v-model="registerForm.phone"
                        :class="{'is-invalid':registerStore.validationErrors?.phone}" class="form-control flat"
                        id="phone" placeholder="Введіть телефон" autocomplete="mobile">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 17px;" icon="fa-solid fa-mobile-screen-button" />
                    </span>
                    <div v-if="registerStore.validationErrors?.phone" class="invalid-feedback">
                        {{ registerStore.validationErrors?.phone[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <SelectBase :options="availableOrganizations" v-model="registerForm.organization"
                        :settings="{allowClear:true}" id="organization" placeholder="Виберіть організацію"
                        :invalid="!!registerStore.validationErrors?.organization?.length"
                        :disabled="registerStore.statusIsPending" />
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 13px;" icon="fa-solid fa-home" />
                    </span>
                    <div v-if="registerStore.validationErrors?.organization" class="invalid-feedback">
                        {{ registerStore.validationErrors?.organization[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <SelectBase :options="getPositionsByOrganization(registerForm.organization)"
                        v-model="registerForm.positions" :settings="{allowClear:true}" id="positions"
                        placeholder="Виберіть позиції" :invalid="!!registerStore.validationErrors?.positions?.length"
                        :disabled="registerStore.statusIsPending" multiple />
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 13px;" icon="fa-solid fa-home" />
                    </span>
                    <div v-if="registerStore.validationErrors?.positions" class="invalid-feedback">
                        {{ registerStore.validationErrors?.positions[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <SelectBase :options="availableCompanies" v-model="registerForm.company"
                        :settings="{allowClear:true}" id="company" placeholder="Виберіть компанію"
                        :invalid="!!registerStore.validationErrors?.company?.length"
                        :disabled="registerStore.statusIsPending" />
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 13px;" icon="fa-solid fa-home" />
                    </span>
                    <div v-if="registerStore.validationErrors?.company" class="invalid-feedback">
                        {{ registerStore.validationErrors?.company[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input type="password" v-model="registerForm.password"
                        :class="{'is-invalid':registerStore.validationErrors?.password}" class="form-control flat"
                        id="password" placeholder="Введіть пароль" autocomplete="current-password">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 15px;" icon="fa-solid fa-lock" />
                    </span>
                    <div v-if="registerStore.validationErrors?.password" class="invalid-feedback">
                        {{ registerStore.validationErrors?.password[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input type="password_confirmation" v-model="registerForm.passwordConfirmation"
                        :class="{'is-invalid':registerStore.validationErrors?.password_confirmation}"
                        class="form-control flat" id="password_confirmation" placeholder="Повторіть пароль"
                        autocomplete="current-password">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 15px;" icon="fa-solid fa-lock" />
                    </span>
                    <div v-if="registerStore.validationErrors?.password_confirmation" class="invalid-feedback">
                        {{ registerStore.validationErrors?.password_confirmation[0] }}
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <router-link :to="{name: 'auth.brandSelect'}"
                    class="btn btn-light btn-block btn-flat w-50 me-5 border-secondary">Вхід</router-link>
                <button type="submit" @click.prevent="registerHandlerTimeout"
                    class="btn btn-primary btn-block btn-flat w-50 border-secondary">Реєстрація</button>
            </div>
        </form>
    </div>

</template>
<script setup>
    import { reactive } from 'vue';
    import { useRegisterStore } from '@/stores/auth/registerStore';
    import { availableOrganizations } from '@/constants/availableOrganizations';
    import { getPositionsByOrganization } from '@/constants/availablePositions';
    import { availableCompanies } from '@/constants/availableCompanies';
    import { useAuthStore } from '@/stores/authStore';
    import { validationRegisterHandler } from '@/services/auth/registerService.js';
    import SelectBase from '@/components/base/SelectBase';

    const authStore = useAuthStore();
    const registerStore = useRegisterStore();

    const registerForm = reactive({
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        organization: '',
        positions: [],
        company: '',
        password: '',
        passwordConfirmation: ''
    });

    const registerHandlerTimeout = () => {
        const payload = {
            'brand': authStore.brand,
            'last_name': registerForm.lastName,
            'first_name': registerForm.firstName,
            'email': registerForm.email,
            'phone': registerForm.phone,
            'organization': registerForm.organization,
            'positions': registerForm.positions,
            'company': registerForm.company,
            'password': registerForm.password,
            'password_confirmation': registerForm.passwordConfirmation

        };

        setTimeout(() => validationRegisterHandler({ registerStore, registerForm, payload }), 1000);
    };

</script>

export const UNION_OF_AUTOMOBILES = { id: 'b3d5a712-7019-e911-819a-00155d1f7d02', text: 'СОЮЗ АВТОМОТІВ' };
export const SINGLE_AUTO = { id: '000037d4-afb5-e411-be01-00e081beb933', text: 'СІНГЛ АВТО' };
export const NIKO_MEGAPOLIS = { id: '7b0fe476-1ddc-df11-9429-00e0815b131d', text: 'НІКО МЕГАПОЛІС' };
export const MTB = { id: '3266cdaa-86bb-e111-848d-00e081beb934', text: 'МТВ' };
export const MODERN_AUTO = { id: '96f35cdb-77ac-e911-81a7-00155d1f050b', text: 'МОДЕРН АВТО' };
export const METEK = { id: '544115f8-054e-ec11-81e4-00155d1f050b', text: 'МЕТЕК' };
export const LION_AUTO = { id: '92c8188b-7524-e911-819a-00155d1f7d02', text: 'ЛІОН АВТО' };
export const ILTA_LVIV = { id: '9b415a78-89e2-df11-9429-00e0815b131d', text: 'ІЛТА ЛЬВІВ' };
export const ILTA_KYIV = { id: 'a0c509cf-e5c4-ea11-81be-00155d1f050b', text: 'ІЛТА КИЇВ' };
export const VIDI_ELEGANCE = { id: '34bcab70-93e2-df11-9429-00e0815b131d', text: 'ВІДІ ЕЛЕГАНС' };
export const AMPIR = { id: 'c14c17ae-1e37-e811-8188-89734a755f6e', text: 'АМПІР' };
export const ALPHA_AVANGARD = { id: '08a3ebcf-687c-e311-b47f-00e081beb934', text: 'АЛЬФА АВАНГАРД' };
export const AIS_CHERKASY = { id: '2e806dd1-9c32-e011-8b08-00e0815b131d', text: 'АІС ЧЕРКАСИ' };
export const AUTO_CHANCE_CENTER = { id: '84f4d28c-0cb9-e411-be01-00e081beb933', text: 'АВТО-ШАНС ЦЕНТР' };
export const AUTO_CENTER_PODILLYA = { id: '370768d1-93e7-e111-9565-005056c00001', text: 'АВТОЦЕНТР ПОДІЛЛЯ' };
export const AUTOSVIT_MYKOLAIV = { id: '5dc858c1-f979-e311-b47f-00e081beb934', text: 'АВТОСВІТ МИКОЛАЇВ' };
export const AUTOPALACE_TERNOPIL = { id: '64e9e7ac-a565-ed11-8207-000c294300c8', text: 'АВТОПАЛАЦ ТЕРНОПІЛЬ' };
export const AUTOLIDER = { id: 'e2c89f51-ee64-df11-9429-00e0815b131d', text: 'АВТОЛІДЕР' };
export const AUTODRIVE_ALLIANCE = { id: '1bda35c6-623e-e911-819b-00155d1f7d02', text: 'АВТОДРАЙВ АЛЬЯНС' };
export const AUTOGRADE_ODESA = { id: '53d4756a-c985-e911-81a1-00155d1f7d02', text: 'АВТОГРАД ОДЕСА' };
export const AUTOALLIANCE_SUMI = { id: '9e923dbb-8f61-ec11-81e6-00155d1f050b', text: 'АВТОАЛЬЯНС СУМИ' };
export const AUTO_VIA = { id: 'd0bbc99c-fe31-e111-953d-00e081beb934', text: 'АВТО ВІА' };
export const RA38 = { id: 'a323c36e-2e7e-ea11-81bb-00155d1f050b', text: '38RA' };

export const availableCompanies = [
    UNION_OF_AUTOMOBILES, SINGLE_AUTO, NIKO_MEGAPOLIS, MTB, MODERN_AUTO, METEK,
    LION_AUTO, ILTA_LVIV, ILTA_KYIV, VIDI_ELEGANCE, AMPIR, ALPHA_AVANGARD, AIS_CHERKASY, AUTO_CHANCE_CENTER,
    AUTO_CENTER_PODILLYA, AUTOSVIT_MYKOLAIV, AUTOPALACE_TERNOPIL, AUTOLIDER, AUTODRIVE_ALLIANCE,
    AUTOGRADE_ODESA, AUTOALLIANCE_SUMI, AUTO_VIA, RA38
];

<template>
    <div class="bg-light p-1 m-1 d-flex  justify-content-center justify-content-lg-between flex-wrap min-w-60 "
        :class="historyTabsClasses">
        <div class="d-flex  justify-content-center flex-wrap ">
            <div @click="props.setCurrentTab('all')"
                class="border border-1 rounded-pill d-flex justify-content-between align-items-center p-1 m-1"
                :class="getTabClasses('all')">

                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ props.counters.all }}</b>
                </div>
                <div class="px-2">Всі</div>
            </div>

            <div @click="props.setCurrentTab('clientVisits')"
                class="border border-1 border-light rounded-pill d-flex justify-content-between align-items-center p-1 m-1"
                :class="getTabClasses('clientVisits')">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ props.counters.clientVisits }}</b>
                </div>
                <div class="px-2">Записи на сервіс</div>
            </div>

            <div @click="props.setCurrentTab('requestsByTel')"
                class="border border-1 border-light rounded-pill d-flex justify-content-between align-items-center p-1 m-1"
                :class="getTabClasses('requestsByTel')">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ props.counters.requestsByTel }}</b>
                </div>
                <div class="px-2">Клієнтські запити</div>
            </div>

            <div @click="props.setCurrentTab('requestsByPhoneCalls')"
                class="border border-1 border-light rounded-pill d-flex justify-content-between align-items-center p-1 m-1"
                :class="getTabClasses('requestsByPhoneCalls')">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ props.counters.requestsByPhoneCalls }}</b>
                </div>
                <div class="px-2">Дзвінки</div>
            </div>

            <div @click="props.setCurrentTab('requestsByEmails')"
                class="border border-1 border-light rounded-pill d-flex justify-content-between align-items-center p-1 m-1 "
                :class="getTabClasses('requestsByEmails')">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ props.counters.requestsByEmails }}</b>
                </div>
                <div class="px-2">Email</div>
            </div>

            <div @click="props.setCurrentTab('requestsByViberSms')"
                class="border border-1 border-light rounded-pill d-flex justify-content-between align-items-center p-1 m-1"
                :class="getTabClasses('requestsByViberSms')">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ props.counters.requestsByViberSms }}</b>
                </div>
                <div class="px-2">СМС</div>
            </div>

            <div @click="props.setCurrentTab('requestsByGas')"
                class="border border-1 border-light rounded-pill d-flex justify-content-between align-items-center p-1 m-1 history-tab"
                :class="getTabClasses('requestsByGas')">
                <div
                    class="bg-white rounded-circle circle-icon text-primary d-flex justify-content-center align-items-center">
                    <b>{{ props.counters.requestsByGas }}</b>
                </div>
                <div class="px-2">GA</div>
            </div>

        </div>

    </div>
</template>
<script setup>
    import { computed, defineProps } from 'vue';
    import { useGrid } from 'vue-screen';
    const props = defineProps({
        counters: {
            type: Object,
            required: true
        },
        currentTab: {
            type: String,
            required: true
        },
        setCurrentTab: {
            type: Function,
            required: true
        }
    }

    );
    const grid = useGrid('bootstrap5');
    const historyTabsClasses = computed(() => ['xxl', 'lg'].includes(grid.breakpoint) ? 'rounded-pill' : 'rounded');
    const getTabClasses = (tab) => {
        return props.currentTab === tab ? 'bg-primary text-white  border-primary' : 'border-light history-tab';
    }
</script>
<style scoped>
    .history-tab:hover {
        border-color: var(--bs-primary) !important;
        cursor: pointer;
    }
</style>
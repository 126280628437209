<template>
    <hr>
    <div class="form-check" v-for="model in bookingCarsStore.bookingCars" :key="model.id">
        <input v-model="contractsStore.filters.modelFilter" class="form-check-input" type="checkbox" :value="model.id"
            :id="'model-'+model.id">
        <label class="form-check-label" :for="'model-'+model.id">
            {{ model.text }}
        </label>
    </div>
</template>
<script setup>
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { onMounted, watch } from 'vue';
    import { useBookingCarsStore } from '@/stores/helpers/bookingCarsStore';
    import { bookingCarsHandlerTimeout } from '@/services/helpers/bookingCarsService';
    import { useAuthStore } from '@/stores/authStore';

    const authStore = useAuthStore();
    const bookingCarsStore = useBookingCarsStore();
    const contractsStore = useContractsStore();

    const updateCars = () => {
        const payload = {
            brand: authStore.brand
        };

        bookingCarsHandlerTimeout({ bookingCarsStore, payload });
    };

    const handlerBrandChange = () => {
        contractsStore.filters.modelFilter = [];
        updateCars();
    }
    watch(() => authStore.brand, handlerBrandChange);

    onMounted(() => {
        updateCars();
    });

</script>
<template>
    <div
        class="bg-white rounded-3 p-2 shadow-sm d-flex justify-content-lg-start justify-content-center align-items-center flex-wrap">
        <span class="text-primary ps-1 fw-bolder ">Історія моделей</span>

        <RoundedPillButtonBase v-for="model in models" :key="model.id"
            class="bg-light text-success shadow-sm mx-1 auto-btn border my-sm-1 mb-2 ps-0  z-3"
            :class="{' border-success justify-content-between':model.active==true, 'justify-content-center border-light':model.active==false}">
            <div v-if="model.active==true"
                class="d-flex justify-content-center align-items-center bg-white rounded-circle p-3 m-0  z-0">
                <font-awesome-icon style="font-size: 18px;" icon="fa-solid fa-car " />
            </div>
            <span class="px-4 fw-bolder text-muted">{{ model.name }}</span>
        </RoundedPillButtonBase>
    </div>
</template>
<script setup>
    import { ref } from 'vue';
    import RoundedPillButtonBase from '@/components/base/RoundedPillButtonBase';
    const models = ref([
        { id: 1, name: 'Movano', active: true },
        { id: 2, name: 'MOKKA-E', active: false },
        { id: 3, name: 'ZafiraLife', active: false },
        { id: 4, name: 'ASTRA', active: false },
        { id: 5, name: 'COMBO LIFE', active: false },

    ]);
</script>
<style>
    .auto-btn {
        min-width: 8rem;
        height: 3.3rem;
    }

    .auto-btn:hover {
        --bs-border-opacity: 1;
        border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
        cursor: pointer;
    }
</style>
<template>
    <div class="bg-white rounded-3 p-2 shadow-sm flex-wrap information-wrapper">
        <div class="d-flex justify-content-between align-items-center p-1">
            <span class="text-primary fw-bolder">Інформація</span>
            <RoundedPillButtonBase class="bg-white text-muted mb-lg-0 mb-3 p-3  border border-white">
                <span class="pe-2 fw-bolder d-lg-block d-none"><br></span>
                <!-- <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-pencil" /> -->
            </RoundedPillButtonBase>
        </div>

        <div class="row my-0">
            <div class="col-lg-4 text-muted">
                <label for="dealer" class="form-label"><br></label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-swatchbook" /></span>
                    <SelectBase class="border-start-0" :options="dealerCentersStore.dealerCenters" id="dealer"
                        v-model="informationForm.dealer" placeholder="Виберіть дилера" />
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="manager" class="form-label">Менеджер</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-chalkboard-user" /></span>
                    <SelectBase class="border-start-0" :options="managersStore.managers" id="manager"
                        v-model="informationForm.manager" placeholder="Виберіть менеджера" />
                </div>
            </div>
            <div class="col-lg-3 text-muted">
                <label for="request-type" class="form-label">Тип запиту</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-file" /></span>
                    <SelectBase class="border-start-0" :options="requestTypesStore.requestTypes" id="request-type"
                        v-model="informationForm.requestType" placeholder="Виберіть тип запиту" />
                </div>
            </div>
        </div>
        <div class="row mt-2 mb-3">
            <div class="col-lg-3 text-muted">
                <label for="flow-channel" class="form-label">Канал надходження</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-share-from-square" /></span>
                    <SelectBase class="border-start-0" :options="flowChannelsStore.flowChannels" id="flow-channel"
                        v-model="informationForm.flowChannel" placeholder="Виберіть канал надходження" />
                </div>
            </div>
            <div class="col-lg-3 text-muted">
                <label for="status" class="form-label">Статус</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-newspaper" /></span>
                    <SelectBase class="border-start-0" :options="statuses" id="status" v-model="informationForm.status"
                        placeholder="Виберіть статус" />
                </div>
            </div>
            <div class="col-lg-3 text-muted">
                <label for="td-check" class="form-label">Пройшов ТД</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <SelectBase class="border-start-0" :options="tdChecks" id="td-check"
                        v-model="informationForm.tdCheck" placeholder="Виберіть" />
                </div>
            </div>
            <div class="col-lg-3 text-muted">
                <label for="td-date" class="form-label">Дата і час ТД</label>
                <div class="d-flex">
                    <span class="input-group-text text-primary bg-white border-end-0 flat rounded-start">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-calendar-check" /></span>
                    <DatePicker type="date" input-class="form-control border-start-0 flat m-0 rounded-end"
                        valueType="format" format="DD.MM.YYYY" id="td-date" style="width: 100%;"
                        v-model:value="informationForm.tdDate">
                    </DatePicker>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { useAuthStore } from '@/stores/authStore';
    import { useTestdrivesStore } from '@/stores/helpers/testdrivesStore';
    import { useDealerCentersStore } from '@/stores/helpers/dealerCentersStore';
    import { useRequestTypesStore } from '@/stores/helpers/requestTypesStore';
    import { useManagersStore } from '@/stores/helpers/managersStore';
    import { useFlowChannelsStore } from '@/stores/helpers/flowChannelsStore';
    import { useContractStore } from '@/stores/contracts/contractStore';
    import { dealerCentersHandlerDebounced } from '@/services/helpers/dealerCentersService';
    import { managersHandlerTimeout } from '@/services/helpers/managersService';
    import { requestTypesHandlerTimeout } from '@/services/helpers/requestTypesService';
    import { flowChannelsHandlerTimeout } from '@/services/helpers/flowChannelsService';
    import { watch, onMounted, computed, ref } from 'vue';
    import moment from 'moment';

    import RoundedPillButtonBase from '@/components/base/RoundedPillButtonBase';
    import DatePicker from 'vue-datepicker-next';
    import SelectBase from '@/components/base/SelectBase';

    const authStore = useAuthStore();
    const testdrivesStore = useTestdrivesStore();
    const dealerCentersStore = useDealerCentersStore();
    const managersStore = useManagersStore();
    const requestTypesStore = useRequestTypesStore();
    const flowChannelsStore = useFlowChannelsStore();
    const contractStore = useContractStore();

    const updateDealerCenters = () => {
        const payload = {
            brand: authStore.brand
        };
        dealerCentersHandlerDebounced({ dealerCentersStore, payload });
    };

    const updateManagers = () => {
        const payload = {
            brand: authStore.brand,
            dealerId: testdrivesStore.testdrive['AccountId'],
            type: 'filteredByTdriveAndStateCode'
        };
        managersHandlerTimeout({ managersStore, payload });
    };

    const updateRequestTypes = () => {
        const payload = {
            brand: authStore.brand,
            key: 'new_recordtype'
        };
        requestTypesHandlerTimeout({ requestTypesStore, payload });
    };

    const updateFlowChannels = () => {
        const payload = {
            brand: authStore.brand,
            key: 'new_channel'
        };
        flowChannelsHandlerTimeout({ flowChannelsStore, payload });
    };

    const handlerBrandChange = () => {
        updateDealerCenters();
        updateManagers();
        updateRequestTypes();
        updateFlowChannels();
    };

    const statuses = computed(function () {
        let statuses = [
            { id: 100000000, text: 'Чекає обробки' },
            { id: 100000001, text: 'В роботі' },
        ];

        if (testdrivesStore.testdrive['New_process_status'] == 100000002) {
            statuses.push({ id: 100000002, text: 'Скасовано' });
        }

        if (testdrivesStore.testdrive['New_process_status'] == 100000003) {
            statuses.push({ id: 100000003, text: 'Продаж' });
        }
        return statuses;
    });

    const tdChecks = computed(function () {
        return [
            { id: 1, text: 'Так' },
            { id: 2, text: 'Ні' },
        ];
    });

    const tdDate = computed(() => {
        if (testdrivesStore.testdrive['New_campaign_data_final']) {
            return moment.unix(testdrivesStore.testdrive['New_campaign_data_final']).format("DD.MM.YYYY");
        }
        return null;
    });

    const informationForm = ref({
        dealer: contractStore.contract['New_salon_id'],
        manager: contractStore.contract['New_web_user_id'],
        requestType: testdrivesStore.testdrive['New_recordtype'],
        flowChannel: testdrivesStore.testdrive['New_chanel'],
        status: testdrivesStore.testdrive['New_process_status'],
        tdCheck: testdrivesStore.testdrive['New_confirmation_presence_td'],
        tdDate: tdDate.value
    });

    const handleContractChange = () => {
        informationForm.value.dealer = contractStore.contract['New_salon_id'];
        informationForm.value.manager = contractStore.contract['New_web_user_id'];
    };

    const handleTestdriveChange = () => {
        informationForm.value.requestType = testdrivesStore.testdrive['New_recordtype'];
        informationForm.value.flowChannel = testdrivesStore.testdrive['New_channel'];
        informationForm.value.status = testdrivesStore.testdrive['New_process_status'];
        informationForm.value.tdCheck = testdrivesStore.testdrive['New_confirmation_presence_td'];
        informationForm.value.tdDate = tdDate.value;
    };

    watch(() => authStore.brand, handlerBrandChange);
    watch(() => contractStore.contract, handleContractChange, { deep: true });
    watch(() => testdrivesStore.testdrive, handleTestdriveChange, { deep: true });

    onMounted(() => {
        updateDealerCenters();
        updateManagers();
        updateRequestTypes();
        updateFlowChannels();
    });
</script>
<style>
    .information-wrapper {
        min-height: 30vh;
    }
</style>
//require('default-passive-events');

import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import { createPinia } from 'pinia';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import {
    faPhone, faMobileScreenButton, faLock, faArrowLeft, faArrowRight, faArrowUp, faArrowDown,
    faUser, faEnvelope, faHome, faBars, faChevronUp,
    faChevronDown, faSearch, faInfoCircle, faComment, faBell, faRightFromBracket, faCircleUser, faFire, faDownload, faSave,
    faCartShopping, faTrash, faCar, faPencil, faXmark, faCheck, faSwatchbook, faChalkboardUser, faFile, faShareFromSquare,
    faNewspaper, faPaperPlane
} from '@fortawesome/free-solid-svg-icons';

import VueTippy from 'vue-tippy'
import { faCalendar, faSquareCheck, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
/* add icons to the library */
library.add(
    faPhone, faMobileScreenButton, faLock, faArrowLeft, faArrowRight, faArrowUp,
    faArrowDown, faUser, faEnvelope, faHome, faBars, faChevronUp,
    faSearch, faChevronDown, faComment, faBell, faInfoCircle, faRightFromBracket,
    faCircleUser, faFire, faDownload, faSave, faCartShopping, faTrash, faCar, faPencil, faCalendar,
    faXmark, faCheck, faSwatchbook, faChalkboardUser, faFile, faShareFromSquare, faNewspaper, faSquareCheck, faCalendarCheck, faPaperPlane
);

const pinia = createPinia();

const app = createApp(App);

app
    .use(router)
    .use(pinia)
    .use(VueTippy)

    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')


import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchTestdriveById } from "@/api/helpersApi";

export const testdriveByIdHandlerTimeout = ({ testdrivesStore, payload }) => {
    setTimeout(() => testdriveByIdHandlerDebounced({ testdrivesStore, payload }), 300);
};

const testdriveByIdHandlerDebounced = debounce(async function ({ testdrivesStore, payload }) {
    testdrivesStore.setStatusPending();
    testdrivesStore.setMainError(null);

    const { response, error } = await withAsync(fetchTestdriveById, payload);

    if (response) {
        testdrivesStore.setStatusSuccess();
        testdrivesStore.testdrive = response.data;
    } else if (error) {
        testdrivesStore.setStatusError();
        testdrivesStore.setMainError(error);
    }
}, 300);





import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchComments, fetchCreateComment } from "@/api/contractsApi";

export const commentsHandlerTimeout = ({ commentsStore, payload }) => {
    setTimeout(() => commentsHandlerDebounced({ commentsStore, payload }), 300);
};

export const commentCreateHandlerTimeout = ({ commentsStore, payload }) => {
    setTimeout(() => commentCreateHandlerDebounced({ commentsStore, payload }), 300);
};

const commentsHandlerDebounced = debounce(async function ({ commentsStore, payload }) {
    commentsStore.setStatusPending();
    commentsStore.setMainError(null);

    const { response, error } = await withAsync(fetchComments, payload);

    if (response) {
        commentsStore.setStatusSuccess();
        commentsStore.comments = response.data;
    } else if (error) {
        commentsStore.setStatusError();
        commentsStore.setMainError(error);
    }
}, 300);

const commentCreateHandlerDebounced = debounce(async function ({ commentsStore, payload }) {
    commentsStore.setStatusPending();
    commentsStore.setMainError(null);

    const { response, error } = await withAsync(fetchCreateComment, payload);

    if (response) {
        commentsStore.setStatusSuccess();
        commentsStore.comments.unshift(response.data);
    }
    else if (error) {
        commentsStore.setStatusError();
        commentsStore.setMainError(error);
    }
}, 300);





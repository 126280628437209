import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchPlanningTasks } from "@/api/contractsApi";

export const planningTasksHandlerTimeout = ({ planningTasksStore, payload }) => {
    setTimeout(() => planningTasksHandlerDebounced({ planningTasksStore, payload }), 300);
};

const planningTasksHandlerDebounced = debounce(async function ({ planningTasksStore, payload }) {
    planningTasksStore.setStatusPending();
    planningTasksStore.setMainError(null);

    const { response, error } = await withAsync(fetchPlanningTasks, payload);

    if (response) {
        planningTasksStore.setStatusSuccess();
        planningTasksStore.tasks = response.data;
    } else if (error) {
        planningTasksStore.setStatusError();
        planningTasksStore.setMainError(error);
    }
}, 300);





import LoginView from '@/views/Auth/LoginView.vue';
import RegisterView from '@/views/Auth/RegisterView.vue';
import BrandSelectView from '@/views/Auth/BrandSelectView.vue';

const parentRoute = '/auth/';

const authRoutes = [
    {
        path: '/',
        name: 'auth.brandSelect',
        component: BrandSelectView
    },
    {
        path: parentRoute + ':brand/register',
        name: 'auth.register',
        component: RegisterView
    },
    {
        path: parentRoute + ':brand/login',
        name: 'auth.login',
        component: LoginView,
        // beforeEnter: (to, from, next) => {
        //     const brand = to.params.brand;
        //     if (availableBrands.includes(brand)) {
        //         next();
        //     } else {
        //         next('/not-found');
        //     }
        // },
    },
];

export default authRoutes;
import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { ref } from 'vue';

export const useDealerCentersStore = defineStore('helpers.dealerCenters', () => {
    const formStatus = useFormStatus();
    const dealerCenters = ref([]);

    return {
        ...formStatus,
        dealerCenters
    };
})
import axios from 'axios';
import { mainBackend } from '@/config/servicesConfig';
import { useAuthStore } from '@/stores/authStore';

const axiosInstance = axios.create({ baseURL: mainBackend.baseURL });

axiosInstance.interceptors.request.use(
    (config) => {
        const authStore = useAuthStore();
        const token = authStore.authToken;

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;

        }
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept'] = 'application/json';

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);
axiosInstance.defaults.headers.common['Accept'] = 'application/json';
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

const api = (axios) => {
    return {
        get: (url, config) => axios.get(url, config),
        post: (url, body, config) => axios.post(url, body, config),
        put: (url, body, config) => axios.put(url, body, config),
        patch: (url, body, config) => axios.patch(url, body, config),
        delete: (url, config) => axios.delete(url, config),
    };
};

const mainAPI = api(axiosInstance);

export { mainAPI };
<template>
    <hr>
    <div class="d-flex mt-1">
        <div class="small-text d-flex flex-column me-1">
            <div class="form-check form-check-inline">
                <input v-model="contractsStore.filters.periodFilterDateType" class="form-check-input" type="radio"
                    id="byContractDate" value="byContractDate">
                <label class="form-check-label small-text" for="byContractDate">По даті контракту</label>
            </div>
            <div>Старт</div>
            <DatePicker v-model:value="contractsStore.filters.periodFilterStartDate" type="date"
                input-class="form-control" valueType="format" format="DD.MM.YYYY" id="periodFilterStartDate"
                style="width: 142px;">
            </DatePicker>
        </div>
        <div class="small-text  d-flex flex-column ms-1">
            <div class="form-check form-check-inline">
                <input v-model="contractsStore.filters.periodFilterDateType" class="form-check-input" type="radio"
                    id="bySaleDate" value="bySaleDate">
                <label class="form-check-label small-text" for="bySaleDate">По даті продажу</label>
            </div>
            <div>Кінець</div>
            <DatePicker v-model:value="contractsStore.filters.periodFilterEndDate" type="date"
                input-class="form-control" valueType="format" format="DD.MM.YYYY" id="periodFilterEndDate"
                style="width: 142px;">
            </DatePicker>
        </div>
    </div>
    <hr>
    <div class="mt-1 d-flex justify-content-between">
        <div class="form-check form-check-inline">
            <input v-model="contractsStore.filters.leadType" class="form-check-input" type="radio" id="leadType1"
                value="leadType1">
            <label class="form-check-label" for="leadType1">ФО</label>
        </div>
        <div class="form-check form-check-inline">
            <input v-model="contractsStore.filters.leadType" class="form-check-input" type="radio" id="leadType2"
                value="leadType2">
            <label class="form-check-label" for="leadType2">ЮО</label>
        </div>
        <div class="form-check form-check-inline">
            <input v-model="contractsStore.filters.leadType" class="form-check-input" type="radio" id="leadType2r"
                value="leadType2r">
            <label class="form-check-label" for="leadType2r">ЮО retail</label>
        </div>
    </div>
</template>
<script setup>
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import DatePicker from 'vue-datepicker-next';
    const contractsStore = useContractsStore();
</script>
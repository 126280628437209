import { createRouter, createWebHistory } from 'vue-router';
import Error404View from '@/views/Errors/Error404View.vue';
import authRoutes from './auth';
import contractsRoutes from './contracts';

const routes = [
  ...authRoutes,
  ...contractsRoutes,

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/:pathMatch(.*)*',
    component: Error404View,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

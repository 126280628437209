import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchRegistrationsHistoryById } from "@/api/contractsApi";

export const registrationsHistoryHandlerTimeout = ({ registrationsHistoryStore, payload }) => {
    setTimeout(() => registrationsHistoryHandlerDebounced({ registrationsHistoryStore, payload }), 300);
};

const registrationsHistoryHandlerDebounced = debounce(async function ({ registrationsHistoryStore, payload }) {
    registrationsHistoryStore.setStatusPending();
    registrationsHistoryStore.setMainError(null);

    const { response, error } = await withAsync(fetchRegistrationsHistoryById, payload);

    if (response) {
        registrationsHistoryStore.setStatusSuccess();
        registrationsHistoryStore.registrationsHistory = response.data;
    } else if (error) {
        registrationsHistoryStore.setStatusError();
        registrationsHistoryStore.setMainError(error);
    }
}, 300);





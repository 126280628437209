import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchPicklist } from "@/api/helpersApi";

export const requestTypesHandlerTimeout = ({ requestTypesStore, payload }) => {
    setTimeout(() => requestTypesHandlerDebounced({ requestTypesStore, payload }), 300);
};

const requestTypesHandlerDebounced = debounce(async function ({ requestTypesStore, payload }) {
    requestTypesStore.setStatusPending();
    requestTypesStore.setMainError(null);

    const { response, error } = await withAsync(fetchPicklist, payload);

    if (response) {
        requestTypesStore.setStatusSuccess();
        requestTypesStore.requestTypes = response.data;
    } else if (error) {
        requestTypesStore.setStatusError();
        requestTypesStore.setMainError(error);
    }
}, 300);





import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { ref } from 'vue';

export const useContractStatusesStore = defineStore('helpers.contractStatusesStore', () => {
    const formStatus = useFormStatus();
    const contractStatuses = ref([]);

    return {
        ...formStatus,
        contractStatuses
    };
})
<template>
    <TopMenuComponent :toggleSidebarHandler="sidebarTogglerHandler" />
    <div id="main-wrapper" :class="{'sb-sidenav-toggled':sidebarIsOpen=='open'}">
        <div class="d-flex" id="wrapper">
            <!-- Sidebar-->
            <div class="border-end border-white border-1 bg-white shadow" id="sidebar-wrapper"
                style="width: 22rem!important;">
                <BrandSelectComponent />
                <LeftNavComponent />
                <div class="mx-3 mt-3 overflow-scroll disable-scrollbar" style="max-height: 65vh;">
                    <slot name="filters"> </slot>
                </div>
            </div>
            <!-- Page content wrapper-->
            <div id="page-content-wrapper" class="bg-light">
                <!-- Top navigation-->
                <div class="navbar-light mx-3">
                    <div class="d-flex flex-wrap justify-content-between align-items-center">
                        <div>
                            <slot name="top-nav"></slot>
                        </div>
                        <TopNavComponent :logoutHandler="authStore.logout" />
                    </div>
                </div>
                <!-- Page content-->
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script setup>
    import 'bootstrap';
    import 'popper.js';
    import { ref } from 'vue';
    import TopMenuComponent from '@/components/common/TopMenuComponent';
    import BrandSelectComponent from '@/components/common/BrandSelectComponent';
    import TopNavComponent from '@/components/common/TopNavComponent';
    import LeftNavComponent from '@/components/common/LeftNavComponent';
    import { useAuthStore } from '@/stores/authStore';
    import 'vue-datepicker-next/locale/uk';
    import 'tippy.js/dist/tippy.css';
    import "@/assets/css/custom-tippy-theme.css";

    const authStore = useAuthStore();
    const sidebarIsOpen = ref(localStorage.getItem('sidebar-is-toggled') ?? 'open');
    const sidebarTogglerHandler = () => {
        sidebarIsOpen.value = sidebarIsOpen.value == 'open' ? 'closed' : 'open';
        localStorage.setItem('sidebar-is-toggled', sidebarIsOpen.value);
    };

</script>
<style lang="scss">
    @import "@/assets/scss/custom-variables";
    @import "@/assets/scss/custom-component-classes";
    @import '~vue-datepicker-next/scss/index.scss';
    @import "~bootstrap/scss/bootstrap.scss";

    .fw-500 {
        font-weight: 500 !important;
    }

    #wrapper {
        overflow-x: hidden;
    }

    #sidebar-wrapper {
        min-height: 100vh;
        margin-left: -22rem;
        transition: margin 0.25s ease-out;
    }

    #sidebar-wrapper .sidebar-heading {
        padding: 0.875rem 1.25rem;
        font-size: 1.2rem;
    }

    #page-content-wrapper {
        min-width: 100vw;
    }

    .sb-sidenav-toggled #wrapper #sidebar-wrapper {
        margin-left: 0;
    }

    @media (min-width: 768px) {
        #sidebar-wrapper {
            margin-left: 0;
        }

        #page-content-wrapper {
            min-width: 0;
            width: 100%;
        }

        .sb-sidenav-toggled #wrapper #sidebar-wrapper {
            margin-left: -22rem;
        }
    }

    .accordion-button::after {
        -webkit-filter: grayscale(1) invert(1);
        filter: grayscale(1) invert(1);
    }

    .disable-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .disable-scrollbar::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    .small-text {
        font-size: 0.8rem;
    }

    .circle-icon {
        height: 40px;
        width: 40px;
    }

    .min-w-60 {
        min-width: 60%;
    }

    .min-vh-50 {
        min-height: 50vh;
    }

    .form-select {
        box-shadow: none !important;
        outline: none !important;
    }

    .form-control {
        box-shadow: none !important;
        outline: none !important;
    }
</style>
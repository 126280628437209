<template>
    <nav class="nav flex-column p-0 m-0">
        <a @click="toggleNested('period-filter')"
            class="btn btn-link d-flex justify-content-between nav-link text-primary" aria-current="page"
            href="#">Період
            <font-awesome-icon style="font-size: 16px;" :icon="togglerIcon('period-filter')" />
        </a>
        <CollapseTransition>
            <div class="ms-3" v-if="isNestedOpen('period-filter')">
                <PeriodFilterPart />
            </div>
        </CollapseTransition>

        <a @click="toggleNested('model-filter')"
            class="btn btn-link d-flex justify-content-between nav-link text-primary" aria-current="page"
            href="#">Модель
            <font-awesome-icon style="font-size: 16px;" :icon="togglerIcon('model-filter')" />
        </a>
        <CollapseTransition>
            <div class="ms-3" v-if="isNestedOpen('model-filter')">
                <ModelFilterPart />
            </div>
        </CollapseTransition>

        <a @click="toggleNested('status-contract-filter')"
            class="btn btn-link d-flex justify-content-between nav-link text-primary" aria-current="page"
            href="#">Статус контракту
            <font-awesome-icon style="font-size: 16px;" :icon="togglerIcon('status-contract-filter')" />
        </a>
        <CollapseTransition>
            <div class="ms-3" v-if="isNestedOpen('status-contract-filter')">
                <StatusContractFilterPart />
            </div>
        </CollapseTransition>

        <a @click="toggleNested('redemption-method-filter')"
            class="btn btn-link d-flex justify-content-between nav-link text-primary" aria-current="page"
            href="#">Спосіб викупу
            <font-awesome-icon style="font-size: 16px;" :icon="togglerIcon('redemption-method-filter')" />
        </a>
        <CollapseTransition>
            <div class="ms-3" v-if="isNestedOpen('redemption-method-filter')">
                <RedemptionMethodFilterPart />
            </div>
        </CollapseTransition>

        <a @click="toggleNested('dealer-filter')"
            class="btn btn-link d-flex justify-content-between nav-link text-primary" aria-current="page" href="#">Дилер
            <font-awesome-icon style="font-size: 16px;" :icon="togglerIcon('dealer-filter')" />
        </a>
        <CollapseTransition>
            <div class="ms-3" v-if="isNestedOpen('dealer-filter')">
                <DealerFilterPart />
            </div>
        </CollapseTransition>
    </nav>
</template>
<script setup>
    import { reactive } from 'vue';
    import CollapseTransition from '@/components/transitions/CollapseTransition';
    import PeriodFilterPart from './PeriodFilterPart';
    import ModelFilterPart from './ModelFilterPart';
    import StatusContractFilterPart from './StatusContractFilterPart';
    import RedemptionMethodFilterPart from './RedemptionMethodFilterPart';
    import DealerFilterPart from './DealerFilterPart';
    import { watch } from 'vue';
    import { useAuthStore } from '@/stores/authStore';
    import { useContractsStore } from '@/stores/contracts/contractsStore';

    import { searchContractsHandlerTimeout } from '@/services/contracts/searchContractsService';

    const authStore = useAuthStore();
    const contractsStore = useContractsStore();

    const menuOpenStates = reactive({
        'period-filter': false,
        'model-filter': false,
        'status-contract-filter': false,
        'redemption-method-filter': false,
        'dealer-filter': false
    });

    const toggleNested = (key) => {
        menuOpenStates[key] = !menuOpenStates[key];
    };

    const isNestedOpen = (key) => {
        return menuOpenStates[key];
    };

    const togglerIcon = (key) => {
        if (isNestedOpen(key)) {
            return 'fa-solid fa-chevron-up';
        }
        return 'fa-solid fa-chevron-down';
    }

    const handleFiltersChange = () => {
        contractsStore.page = 1;
        searchContractsHandlerTimeout({ authStore, contractsStore });
    }

    watch(() => contractsStore.filters, handleFiltersChange, { deep: true });
    watch(() => authStore.brand, handleFiltersChange, { deep: true });
</script>
<template>
    <SidebarLeftLayout>
        <template v-slot:top-nav>
            <TopNavPart />
        </template>
        <template v-slot:default>
            <div class="container-fluid">
                <ModelHistoryPart />
                <div class="row mt-2">
                    <div class="col-lg-7 my-md-0 mb-1 mx-0 pe-lg-1">
                        <ClientContactsPart />
                    </div>
                    <div class="col-lg-5 my-md-0 mt-1 mx-0 ps-lg-1">
                        <PlanningPart />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-7 my-md-0 mb-1 mx-0 pe-lg-1">
                        <InformationPart />
                    </div>
                    <div class="col-lg-5 my-md-0 my-1 mx-0 ps-lg-1">
                        <CommentsPart />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-12">
                        <HistoryPart />
                    </div>
                </div>
                <div class="row mt-2 mb-5">
                    <div class="col-lg-7 my-md-0 mb-1 mx-0 pe-lg-1">
                        <ContractPart />
                    </div>
                    <div class="col-lg-5 my-md-0 mt-1 mx-0 ps-lg-1">

                    </div>
                </div>
            </div>
        </template>
    </SidebarLeftLayout>
</template>

<script setup>
    import { useAuthStore } from '@/stores/authStore';
    import { watch, onMounted } from 'vue';
    import { useTestdrivesStore } from '@/stores/helpers/testdrivesStore';
    import { useContractStore } from '@/stores/contracts/contractStore';
    import { useRoute } from "vue-router";
    import { testdriveByIdHandlerTimeout } from '@/services/helpers/testdrivesService';
    import { getContractByTdriveIdHandlerTimeout } from '@/services/contracts/contractService';

    import SidebarLeftLayout from '@/layouts/SidebarLeftLayout';
    import TopNavPart from './LeadCardParts/TopNavPart';
    import ModelHistoryPart from './LeadCardParts/ModelHistoryPart';
    import ClientContactsPart from './LeadCardParts/ClientContactsPart';
    import PlanningPart from './LeadCardParts/PlanningPart';
    import InformationPart from './LeadCardParts/InformationPart';
    import CommentsPart from './LeadCardParts/CommentsPart';
    import HistoryPart from './LeadCardParts/HistoryPart';
    import ContractPart from './LeadCardParts/ContractPart';

    const authStore = useAuthStore();
    const testdrivesStore = useTestdrivesStore();
    const contractStore = useContractStore();

    const route = useRoute();

    const updateTestdrive = () => {
        const payload = {
            brand: authStore.brand,
            id: route.params.tdriveId
        };

        testdriveByIdHandlerTimeout({ testdrivesStore, payload });
    };

    const updateContract = () => {
        const payload = {
            brand: authStore.brand,
            id: route.params.tdriveId
        };

        getContractByTdriveIdHandlerTimeout({ contractStore, payload });
    }

    const handlerBrandChange = () => {
        updateTestdrive();
        updateContract();
    };

    const handlerTdriveIdChange = () => {
        updateTestdrive();
        updateContract();
    };

    watch(() => authStore.brand, handlerBrandChange);
    watch(() => route.params.tdriveId, handlerTdriveIdChange);

    onMounted(() => {
        updateTestdrive();
        updateContract();
    });
</script>

<style>
    .icon-circle {
        width: 4rem;
        height: 4rem;
    }
</style>
<template>
    <div class="bg-white m-3 ">
        <form class="p-4">
            <div v-if="loginStore.mainError" class="alert alert-danger flat" role="alert">{{ loginStore.mainError }}
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input type="text" v-model="loginForm.phone" class="form-control flat"
                        :class="{'is-invalid':loginStore.validationErrors?.phone}" id="phone"
                        placeholder="Введіть номер телефону" autocomplete="mobile">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 16px;" icon="fa-solid fa-mobile-screen-button" />
                    </span>
                    <div v-if="loginStore.validationErrors?.phone" class="invalid-feedback">
                        {{ loginStore.validationErrors?.phone[0] }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input type="password" v-model="loginForm.password" class="form-control flat"
                        :class="{'is-invalid':loginStore.validationErrors?.password}" id="password"
                        placeholder="Введіть пароль" autocomplete="current-password">
                    <span class="input-group-text flat bg-transparent text-primary">
                        <font-awesome-icon style="font-size: 15px;" icon="fa-solid fa-lock" />
                    </span>
                    <div v-if="loginStore.validationErrors?.password" class="invalid-feedback">
                        {{ loginStore.validationErrors?.password[0] }}
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <router-link :to="{name:'auth.register'}"
                    class="btn btn-light btn-block btn-flat w-50 me-5 border-secondary">Реєстрація</router-link>
                <button @click.prevent="loginHandlerTimeout" type="submit"
                    class="btn btn-primary btn-block btn-flat w-50 border-secondary">Увійти</button>
            </div>
            <div class="d-flex justify-content-between mt-5">
                <router-link :to="{name: 'auth.brandSelect',}" class="btn btn-default">
                    <small class="text-primary me-1">
                        До вибору бренду <font-awesome-icon style="font-size: 16px;" icon="fa-solid fa-arrow-right" />
                    </small>
                </router-link>
                <button class="btn btn-default" data-bs-toggle="modal" data-bs-target="#modal-support">
                    <small class="text-primary">
                        <font-awesome-icon style="font-size: 12px;" icon="fa-solid fa-phone" />
                        Служба підтримки
                    </small>
                </button>
            </div>
        </form>
    </div>
</template>
<script setup>
    import { reactive } from 'vue';
    import { useAuthStore } from '@/stores/authStore';
    import { useLoginStore } from '@/stores/auth/loginStore';
    import { validationLoginHandler } from '@/services/auth/loginService';

    const authStore = useAuthStore();
    const loginStore = useLoginStore();

    const loginForm = reactive({
        phone: '',
        password: ''
    });

    const loginHandlerTimeout = () => {
        const payload = {
            'brand': authStore.brand,
            'phone': loginForm.phone,
            'password': loginForm.password
        };

        setTimeout(() => validationLoginHandler({ authStore, loginStore, loginForm, payload }), 1000);
    };   
</script>@/services/auth/loginService
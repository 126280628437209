import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchClientContacts } from "@/api/contractsApi";

export const clientContactsHandlerTimeout = ({ clientContactsStore, payload }) => {
    setTimeout(() => clientContactsHandlerDebounced({ clientContactsStore, payload }), 300);
};

const clientContactsHandlerDebounced = debounce(async function ({ clientContactsStore, payload }) {
    clientContactsStore.setStatusPending();
    clientContactsStore.setMainError(null);

    const { response, error } = await withAsync(fetchClientContacts, payload);

    if (response) {
        clientContactsStore.setStatusSuccess();        
        clientContactsStore.clientContacts = response.data;
    } else if (error) {
        clientContactsStore.setStatusError();
        clientContactsStore.setMainError(error);
    }
}, 300);





import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { userStatuses } from '@/constants/userStatuses';
import { useRouter, useRoute } from "vue-router";
import { availableBrands } from '@/constants/availableBrands';

export const useAuthStore = defineStore('auth', () => {
    const router = useRouter();
    const route = useRoute();
    const brand = ref(JSON.parse(localStorage.getItem('brand')) || null);
    const user = ref(JSON.parse(localStorage.getItem('user')) || null);
    const userStatus = ref(JSON.parse(localStorage.getItem('userStatus')) || null);

    function login(authUser, status = userStatuses.SUCCESS_LOGINED) {
        setStatus(status);
        setUser(authUser);

        if (status === userStatuses.SUCCESS_LOGINED) {
            router.push({
                name: 'contracts.index', params: {
                    brand: brand.value
                }
            });
            return;
        }
    }

    function logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('userStatus');
        localStorage.removeItem('brand');
        user.value = null;
        userStatus.value = null;
        brand.value = null;
        router.push({
            name: 'auth.brandSelect',
        });
    }

    function setStatus(status) {
        userStatus.value = status;
        localStorage.setItem('userStatus', JSON.stringify(status));
    }

    function setUser(authUser) {
        user.value = authUser;
        localStorage.setItem('user', JSON.stringify(authUser));
    }

    function checkBrand() {

        if (!availableBrands.includes(route.params.brand)) {
            router.push('not-found');
            return;
        }

        brand.value = route.params.brand;
        localStorage.setItem('brand', JSON.stringify(route.params.brand));
    }

    function setBrand(b) {

        if (!availableBrands.includes(b)) {
            router.push('not-found');
            return;
        }

        brand.value = b;
        localStorage.setItem('brand', JSON.stringify(b));
        const currentParams = { ...route.params };
        currentParams.brand = b;
        router.push({ params: currentParams });
    }

    const isAuth = computed(() => userStatus.value === userStatuses.SUCCESS_LOGINED);

    return {
        isAuth,
        login, logout, setUser, setBrand, checkBrand,
        user, userStatus, brand
    };
})
<template>
    <div class="bg-white rounded-3 p-2 shadow-sm flex-wrap client-contacts-wrapper">
        <div class="d-flex justify-content-between align-items-center p-1 ">
            <span class="text-primary fw-bolder">Контакти клієнта</span>
            <RoundedPillButtonBase class="bg-white text-muted mb-lg-0 mb-3 p-3  border border-white">
                <span class="pe-2 fw-bolder d-lg-block d-none"><br></span>
                <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-pencil" />
            </RoundedPillButtonBase>
        </div>
        <div class="alert alert-danger" role="alert" v-if="clientContactsStore.statusIsError">
            {{ clientContactsStore.mainError }}
        </div>
        <LoaderBase class="p-1 text-primary" v-if="clientContactsStore.statusIsPending" />
        <div class="table-responsive"
            v-if="clientContactsStore.statusIsSuccess && clientContactsStore.clientContacts != {}">
            <table class="table table-client-contacts">
                <thead>
                    <tr>
                        <th>Клієнт</th>
                        <th>Дата народження</th>
                        <th>Номер телефону</th>
                        <th>Ел. адреса</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ clientContactsStore.clientContacts.lastName }} {{
                            clientContactsStore.clientContacts.firstName }} {{
                            clientContactsStore.clientContacts.middleName }}</td>
                        <td>{{ clientContactsStore.clientContacts.birthday }}</td>
                        <td>{{ clientContactsStore.clientContacts.mobilePhone }}</td>
                        <td>{{ clientContactsStore.clientContacts.email }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
    import { clientContactsHandlerTimeout } from '@/services/contracts/clientContactsService';
    import { useAuthStore } from '@/stores/authStore';
    import { useClientContactsStore } from '@/stores/contracts/clientContactsStore';
    import { onMounted, watch } from 'vue';
    import { useRoute } from "vue-router";

    import RoundedPillButtonBase from '@/components/base/RoundedPillButtonBase';
    import LoaderBase from '@/components/base/LoaderBase';

    const authStore = useAuthStore();
    const clientContactsStore = useClientContactsStore();
    const route = useRoute();

    const updateClientContacts = () => {
        let payload = {
            brand: authStore.brand,
            id: route.params.tdriveId
        };
        clientContactsHandlerTimeout({ clientContactsStore, payload });
    };

    watch(() => authStore.brand, updateClientContacts, { deep: true });
    watch(() => route.params.tdriveId, updateClientContacts, { deep: true });

    onMounted(() => {
        updateClientContacts();
    });
</script>

<style scoped>
    .client-contacts-wrapper {
        min-height: 25vh;
    }

    .table-client-contacts th {
        color: grey;
        text-align: left;
    }

    .table-client-contacts td {
        vertical-align: middle;
        width: 100px;
        height: 100px;
        padding: 10px;
        --bs-text-opacity: 1;
        color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
    }
</style>
<template>
    <div class="row p-2" v-if="contractsStore.contracts.length && !contractsStore.statusIsPending ">
        <div class="col-md-2">
            Показані з {{ contractsStore.from }} по {{ contractsStore.to }} з {{ contractsStore.total }} записів
        </div>
        <div class="col-md-10 text-center">
            <div>
                <button @click.prevent="contractsStore.prevPage" class="btn btn-link btn-sm me-2" type="button"
                    :disabled="!contractsStore.hasPrevPage" data-bs-toggle="tooltip" data-bs-placement="top"
                    aria-label="Prev" data-bs-original-title="Prev">
                    <svg class="svg-inline--fa fa-chevron-left fa-w-10" aria-hidden="true" focusable="false"
                        data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512" data-fa-i2svg="">
                        <path fill="currentColor"
                            d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z">
                        </path>
                    </svg>
                </button>

                <!-- <button @click.prevent="contractsStore.setCurrentPage(1)" class="btn btn-sm btn-falcon-default me-2">
                    Первая
                </button> -->

                <button v-for="pageNumber in contractsStore.displayedPages" :key="pageNumber"
                    @click.prevent="contractsStore.setCurrentPage(pageNumber)" class="btn btn-sm btn-link me-2"
                    :class="{'text-primary': pageNumber === contractsStore.page}"
                    :disabled="pageNumber === contractsStore.page">
                    {{ pageNumber }}
                </button>

                <!-- <button @click.prevent="contractsStore.setCurrentPage(contractsStore.total)" :disabled="!contractsStore.hasNextPage"
                    class="btn btn-sm btn-falcon-default me-2">
                    Последняя
                </button> -->

                <button @click.prevent="contractsStore.nextPage" class="btn btn-link btn-sm" type="button"
                    :disabled="!contractsStore.hasNextPage" data-bs-toggle="tooltip" data-bs-placement="top"
                    aria-label="Next" data-bs-original-title="Next">
                    <svg class="svg-inline--fa fa-chevron-right fa-w-10" aria-hidden="true" focusable="false"
                        data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512" data-fa-i2svg="">
                        <path fill="currentColor"
                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z">
                        </path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { watch } from 'vue';
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { useAuthStore } from '@/stores/authStore';
    import { searchContractsHandlerTimeout } from '@/services/contracts/searchContractsService';

    const contractsStore = useContractsStore();
    const authStore = useAuthStore();

    const handlePageChange = (newPage) => {
        searchContractsHandlerTimeout({ authStore, contractsStore });
        contractsStore.updateRoutePage(newPage);
    }

    watch(() => contractsStore.page, handlePageChange);
</script>
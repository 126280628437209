import { mainAPI } from './api';

const URLS = {
    loginUrl: 'auth/login',
    registerUrl: 'auth/register',
};

export const fetchLogin = (payload) => {
    return mainAPI.post(URLS.loginUrl, payload,);
};

export const fetchRegister = (payload) => {
    return mainAPI.post(URLS.registerUrl, payload,);
};

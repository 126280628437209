<template>
    <div class="bg-white rounded-3 p-2 shadow-sm flex-wrap contract-wrapper">
        <div class="d-flex justify-content-between align-items-center p-1">
            <span class="text-primary fw-bolder">Контракт</span>
            <RoundedPillButtonBase class="bg-primary text-white  mb-lg-0 mb-3 p-3 shadow-sm border">
                <span class="pe-2 fw-bolder">Зберегти</span>
                <font-awesome-icon style="font-size: 20px;" icon="fa-solid fa-save" />
            </RoundedPillButtonBase>
        </div>
        <div class="rounded-3 bg-light p-2 mt-3">
            <div class="row text-muted">
                <div class="col-lg-4">
                    <div>CRM ID замовлення у виробництво</div>
                    <div> <b>-</b> </div>
                </div>
                <div class="col-lg-4">
                    <div>Номер замовлення у виробництво</div>
                    <div> <b>{{ contractForm.orderNumber }} </b> </div>
                </div>
                <div class="col-lg-4">
                    <div>Номер кузова</div>
                    <div> <b>{{ contractForm.vinNumber }}</b> </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 text-muted">
                <label for="contract_date" class="form-label">Дата контракту <span class="text-danger">*</span></label>
                <div class="d-flex">
                    <span class="input-group-text text-primary bg-white border-end-0 flat rounded-start">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-calendar-check" /></span>
                    <DatePicker v-model:value="contractForm.contractDate" type="date"
                        input-class="form-control border-start-0 flat m-0 rounded-end" valueType="format"
                        format="DD.MM.YYYY" id="contract_date" style="width: 100%;">
                    </DatePicker>
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="status_auto" class="form-label">Статус авто <span class="text-danger">*</span></label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <SelectBase :options="warehousesStore.warehouses" v-model="contractForm.statusAuto"
                        :settings="{allowClear:true}" id="status_auto" placeholder="Виберіть статус авто" />
                </div>
            </div>
            <div class="col-lg-4 text-muted">

                <label for="complectation" class="form-label">Комплектація</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <SelectBase :options="bookingCarsStore.bookingCars" v-model="contractForm.complectation"
                        :settings="{allowClear:true}" id="complectation" placeholder="Виберіть комплектацію" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 text-muted">
                <label for="color_auto" class="form-label">Колір авто</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <input type="text" v-model="contractForm.colorAuto" class="form-control border-start-0"
                        id="color_auto" />


                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="interior_code" class="form-label">Код інтер`єру </label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <input type="text" v-model="contractForm.interiorCode" class="form-control border-start-0"
                        id="interior_code" />
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="options" class="form-label">Опції</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <input type="text" v-model="contractForm.options" class="form-control border-start-0"
                        id="options" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 text-muted">
                <label for="lcdv_code" class="form-label">LCDV-код версії </label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <input type="text" v-model="contractForm.lcdvCode" class="form-control border-start-0"
                        id="lcdv_code" />
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="logistic_status" class="form-label">Логістичний статус </label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <SelectBase :options="logisticStatusesStore.logisticStatuses" v-model="contractForm.logisticStatus"
                        :settings="{allowClear:true}" id="logistic_status" placeholder="Виберіть логістичний статус" />
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="location_auto" class="form-label">Місцезнаходження авто </label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" /></span>
                    <SelectBase :options="carLocations" v-model="contractForm.locationAuto"
                        :settings="{allowClear:true}" id="location_auto" placeholder="Виберіть місцезнаходження авто" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 text-muted">
                <label for="clearance_date" class="form-label">Дата митного очищення </label>
                <div class="d-flex">
                    <span class="input-group-text text-primary bg-white border-end-0 flat rounded-start">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-calendar-check" /></span>
                    <DatePicker v-model:value="contractForm.clearanceDate" type="date"
                        input-class="form-control border-start-0 flat m-0 rounded-end" valueType="format"
                        format="DD.MM.YYYY" id="clearance_date" style="width: 100%;">
                    </DatePicker>
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="arrival_dealer_date" class="form-label">Дата прибуття до дилера</label>
                <div class="d-flex">
                    <span class="input-group-text text-primary bg-white border-end-0 flat rounded-start">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-calendar-check" /></span>
                    <DatePicker type="date" v-model:value="contractForm.arrivalDealerDate"
                        input-class="form-control border-start-0 flat m-0 rounded-end" valueType="format"
                        format="DD.MM.YYYY" id="arrival_dealer_date" style="width: 100%;">
                    </DatePicker>
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="pay_type" class="form-label">Тип оплати</label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" />
                    </span>
                    <SelectBase :options="payTypes" v-model="contractForm.payType" :settings="{allowClear:true}"
                        id="pay_type" placeholder="Виберіть тип оплати" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 text-muted">
                <label for="status_contract" class="form-label">Статус контракту<span
                        class="text-danger">*</span></label>
                <div class="input-group">
                    <span class="input-group-text text-primary bg-white border-end-0">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-square-check" />
                    </span>
                    <SelectBase :options="contractStatusesStore.contractStatuses" v-model="contractForm.contractStatus"
                        :settings="{allowClear:true}" id="status_contract" placeholder="Виберіть статус контракту" />
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="plan_sale_date" class="form-label">Запланована дата продажу</label>
                <div class="d-flex">
                    <span class="input-group-text text-primary bg-white border-end-0 flat rounded-start">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-calendar-check" /></span>
                    <DatePicker v-model:value="contractForm.planSaleDate" type="date"
                        input-class="form-control border-start-0 flat m-0 rounded-end" valueType="format"
                        format="DD.MM.YYYY" id="plan_sale_date" style="width: 100%;">
                    </DatePicker>
                </div>
            </div>
            <div class="col-lg-4 text-muted">
                <label for="actual_sale_date" class="form-label">Фактична дата продажу</label>
                <div class="d-flex">
                    <span class="input-group-text text-primary bg-white border-end-0 flat rounded-start">
                        <font-awesome-icon style="font-size: 20px;" icon="fa-regular fa-calendar-check" /></span>
                    <DatePicker v-model:value="contractForm.actualSaleDate" type="date"
                        input-class="form-control border-start-0 flat m-0 rounded-end" valueType="format"
                        format="DD.MM.YYYY" id="actual_sale_date" style="width: 100%;">
                    </DatePicker>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>

    import { useAuthStore } from '@/stores/authStore';
    import { useContractStore } from '@/stores/contracts/contractStore';
    import { useBookingCarsStore } from '@/stores/helpers/bookingCarsStore';
    import { useBookingCarStore } from '@/stores/helpers/bookingCarStore';
    import { useTestdrivesStore } from '@/stores/helpers/testdrivesStore';
    import { useWarehousesStore } from '@/stores/helpers/warehousesStore';
    import { useLogisticStatusesStore } from '@/stores/helpers/logisticStatusesStore';
    import { useContractStatusesStore } from '@/stores/helpers/contractStatusesStore';
    import { ref, watch, computed, onMounted } from 'vue';
    import moment from 'moment';
    import { bookingCarsByParentModelHandlerTimeout } from '@/services/helpers/bookingCarsService';
    import { findCarByModelVersionIdHandlerTimeout } from '@/services/helpers/bookingCarService';
    import { warehousesHandlerTimeout } from '@/services/helpers/warehousesService';
    import { logisticStatusesHandlerTimeout } from '@/services/helpers/logisticStatusesService';
    import { contractStatusesHandlerTimeout } from '@/services/helpers/contractStatusesService';
    import { carLocations } from '@/constants/availableCarLocations';
    import RoundedPillButtonBase from '@/components/base/RoundedPillButtonBase';
    import DatePicker from 'vue-datepicker-next';
    import SelectBase from '@/components/base/SelectBase';

    const authStore = useAuthStore();
    const contractStore = useContractStore();
    const bookingCarsStore = useBookingCarsStore();
    const bookingCarStore = useBookingCarStore();
    const testdrivesStore = useTestdrivesStore();
    const warehousesStore = useWarehousesStore();
    const logisticStatusesStore = useLogisticStatusesStore();
    const contractStatusesStore = useContractStatusesStore();

    const contractDate = computed(function () {
        if (contractStore.contract['New_contractdate']) {
            return moment.unix(contractStore.contract['New_contractdate']).format("DD.MM.YYYY");
        }
        return null;
    });

    const clearanceDate = computed(function () {
        if (contractStore.contract['New_customsclearance_date']) {
            return moment.unix(contractStore.contract['New_customsclearance_date']).format("DD.MM.YYYY");
        }
        return null;
    });

    const arrivalDealerDate = computed(function () {
        if (contractStore.contract['New_arrivaltodealer_date']) {
            return moment.unix(contractStore.contract['New_arrivaltodealer_date']).format("DD.MM.YYYY");
        }
        return null;
    });

    const parentmodel = computed(function () {

        if (bookingCarStore.bookingCar['New_parentmodel']) {
            return contractStore.contract['New_model'];
        }

        return testdrivesStore.testdrive['Su_carcomplId']
    });

    const payTypes = computed(function () {

        if (contractStore.contract['New_contractstatus'] == 1 || contractStore.contract['New_contractstatus'] == 6) {
            return contractStatusesStore.contractStatuses;
        }

        return contractStatusesStore.contractStatuses.filter((contractStatus) =>
            [3, 4, 5].includes(contractStatus.id)
        );
    });

    const planSaleDate = computed(function () {
        if (contractStore.contract['New_postponeddelivery']) {
            return moment.unix(contractStore.contract['New_postponeddelivery']).format("DD.MM.YYYY");
        }
        return null;
    });

    const actualSaleDate = computed(function () {
        if (contractStore.contract['New_delivered']) {
            return moment.unix(contractStore.contract['New_delivered']).format("DD.MM.YYYY");
        }
        return null;
    });

    const contractForm = ref({
        contractDate: null,
        orderNumber: null,
        vinNumber: null,
        complectation: null,
        statusAuto: null,
        colorAuto: null,
        interiorCode: null,
        options: null,
        lcdvCode: null,
        logisticStatus: null,
        locationAuto: null,
        clearanceDate: null,
        arrivalDealerDate: null,
        payType: null,
        contractStatus: null,
        planSaleDate: null,
        actualSaleDate: null,
    });

    const updateCar = () => {
        const payload = {
            brand: authStore.brand,
            modelVersionId: contractStore.contract['New_modelversionid']
        };

        if (contractStore.contract['New_modelversionid']) {
            findCarByModelVersionIdHandlerTimeout({ bookingCarStore, payload });
        }

    };

    const updateCars = () => {
        const payload = {
            brand: authStore.brand,
            parentmodel: parentmodel.value
        };

        if (parentmodel.value) {
            bookingCarsByParentModelHandlerTimeout({ bookingCarsStore, payload });
        }

    };

    const updateWarehouses = () => {
        const payload = {
            brand: authStore.brand,
            key: 'new_warehouse'
        };

        warehousesHandlerTimeout({ warehousesStore, payload });
    };

    const updateLogisticStatuses = () => {
        const payload = {
            brand: authStore.brand,
            key: 'new_logistics_status'
        };
        logisticStatusesHandlerTimeout({ logisticStatusesStore, payload });
    }

    const updateContractStatuses = () => {
        const payload = {
            brand: authStore.brand,
            key: 'new_contractstatus'
        };
        contractStatusesHandlerTimeout({ contractStatusesStore, payload });
    }

    const handlerParentModelChange = () => {
        updateCars();
    };

    const handlerBrandChange = () => {
        updateCars();
        updateCar();
        updateWarehouses();
        updateLogisticStatuses();
        updateContractStatuses();
    };

    const handlerContractChange = () => {
        contractForm.value.contractDate = contractDate.value;
        contractForm.value.orderNumber = contractStore.contract['New_ordernumber'] ?? null;
        contractForm.value.vinNumber = contractStore.contract['New_vin'] ?? null;
        contractForm.value.complectation = contractStore.contract['New_modelversionid'] ?? null;
        contractForm.value.statusAuto = contractStore.contract['New_warehouse'] ?? null;
        contractForm.value.colorAuto = contractStore.contract['New_colour_code'] ?? null;
        contractForm.value.interiorCode = contractStore.contract['New_interior_code'] ?? null;
        contractForm.value.options = contractStore.contract['New_options_string'] ?? null;
        contractForm.value.logisticStatus = contractStore.contract['New_logistics_status'] ?? null;
        contractForm.value.locationAuto = contractStore.contract['New_carlocation'] ?? null;
        contractForm.value.clearanceDate = clearanceDate.value;
        contractForm.value.arrivalDealerDate = arrivalDealerDate.value;
        contractForm.value.payType = contractStore.contract['New_contractstatus'] ?? null;
        contractForm.value.contractStatus = contractStore.contract['New_vehiclestatus'] ?? null;
        contractForm.value.planSaleDate = planSaleDate.value;
        contractForm.value.actualSaleDate = actualSaleDate.value;

        updateCar();
    };

    const handlerCarChange = () => {
        contractForm.value.lcdvCode = bookingCarStore.bookingCar['New_commercialdenominationcode'] ?? null;
    };

    watch(() => contractStore.contract, handlerContractChange, { deep: true });
    watch(() => authStore.brand, handlerBrandChange, { deep: true });
    watch(() => parentmodel.value, handlerParentModelChange, { deep: true });
    watch(() => bookingCarStore.bookingCar, handlerCarChange, { deep: true });

    onMounted(() => {
        updateCars();
        updateCar();
        updateWarehouses();
        updateLogisticStatuses();
        updateContractStatuses();
    });
</script>
<style scoped>
    .contract-wrapper {
        min-height: 30vh;
    }
</style>
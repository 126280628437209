<template>
    <button class="d-flex justify-content-center align-items-center rounded-circle icon-circle">
        <slot></slot>
    </button>
</template>
<style scoped>
    .icon-circle {
        width: 3.5rem;
        height: 3.5rem;
    }
</style>
import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchRegistrationsByPhoneNumber } from "@/api/contractsApi";

export const registrationsByPhoneNumberHandlerTimeout = ({ registrationsByPhoneNumberStore, payload }) => {
    setTimeout(() => registrationsHistoryHandlerDebounced({ registrationsByPhoneNumberStore, payload }), 300);
};

const registrationsHistoryHandlerDebounced = debounce(async function ({ registrationsByPhoneNumberStore, payload }) {
    registrationsByPhoneNumberStore.setStatusPending();
    registrationsByPhoneNumberStore.setMainError(null);

    const { response, error } = await withAsync(fetchRegistrationsByPhoneNumber, payload);

    if (response) {
        registrationsByPhoneNumberStore.setStatusSuccess();
        registrationsByPhoneNumberStore.registrationsByPhoneNumber = response.data;
    } else if (error) {
        registrationsByPhoneNumberStore.setStatusError();
        registrationsByPhoneNumberStore.setMainError(error);
    }
}, 300);





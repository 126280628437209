import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { usePagination } from '@/helpers/pagination';
import { useSort } from '@/helpers/sort';
import { reactive, ref, computed } from 'vue';
import { RED_CONTRACTS_TAB, IN_WORK_CONTRACTS_TAB, SELL_CONTRACTS_TAB, CANCEL_CONTRACTS_TAB, ALL_CONTRACTS_TAB } from '@/constants/contractTabTypes';

export const useContractsStore = defineStore('contracts.index', () => {
    const formStatus = useFormStatus();
    const pagination = usePagination();
    const sort = useSort();

    const contracts = ref([]);
    const tabCounters = ref([]);

    const currentTab = ref(RED_CONTRACTS_TAB);

    const currentTabIsRedContractsTab = computed(() => currentTab.value === RED_CONTRACTS_TAB);
    const currentTabIsInWorkContractsTab = computed(() => currentTab.value === IN_WORK_CONTRACTS_TAB);
    const currentTabIsSellContractsTab = computed(() => currentTab.value === SELL_CONTRACTS_TAB);
    const currentTabIsCancelContractsTab = computed(() => currentTab.value === CANCEL_CONTRACTS_TAB);
    const currentTabIsAllContractsTab = computed(() => currentTab.value === ALL_CONTRACTS_TAB);

    function setCurrentTab(tab) {
        currentTab.value = tab;
    }

    const search = ref('');

    const filters = reactive({
        periodFilterDateType: 'byContractDate',
        periodFilterStartDate: null,
        periodFilterEndDate: null,
        leadType: null,
        modelFilter: [],
        statusContractFilter: [],
        redemptionMethodFilter: [],
        dealerFilter: []
    });

    return {
        ...formStatus,
        ...pagination,
        ...sort,
        currentTabIsRedContractsTab, currentTabIsInWorkContractsTab, currentTabIsSellContractsTab, currentTabIsCancelContractsTab, currentTabIsAllContractsTab,
        setCurrentTab,
        contracts,
        tabCounters,
        search,
        filters,
        currentTab
    };
})
import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchRegister } from "@/api/authApi";

export const validationRegisterHandler = ({ registerStore, payload }) => {


    // let validationErrors = {};

    // if (registerForm.lastName.length == 0) {
    //     (validationErrors.last_name = []).push('Потрібно заповнити поле Прізвище.');
    // }
    // if (registerForm.firstName.length == 0) {
    //     (validationErrors.first_name = []).push('Потрібно заповнити поле Ім`я.');
    // }
    // if (registerForm.email.length == 0) {
    //     (validationErrors.email = []).push('Потрібно заповнити поле Email.');
    // }
    // if (registerForm.phone.length == 0) {
    //     (validationErrors.phone = []).push('Потрібно заповнити поле Телефон.');
    // }
    // if (registerForm.organization.length == 0) {
    //     (validationErrors.organization = []).push('Потрібно заповнити поле Організація.');
    // }
    // if (registerForm.positions.length == 0) {
    //     (validationErrors.positions = []).push('Потрібно заповнити поле Позиція.');
    // }
    // if (registerForm.company.length == 0) {
    //     (validationErrors.company = []).push('Потрібно заповнити поле Компанія.');
    // }
    // if (registerForm.password.length == 0) {
    //     (validationErrors.password = []).push('Потрібно заповнити поле пароль.');
    // }
    // if (registerForm.passwordConfirmation.length == 0) {
    //     (validationErrors.password_confirmation = []).push('Потрібно заповнити поле пароль.');
    // }

    // if ((Object.keys(validationErrors).length !== 0)) {
    //     registerStore.setStatusValidationError();
    //     registerStore.setValidationErrors(validationErrors);
    // }
    // else {
    registerHandlerDebounced({ registerStore, payload });
    // }
};

export const registerHandlerDebounced = debounce(async function ({ registerStore, payload }) {

    registerStore.setStatusPending();
    registerStore.setMainError(null);
    registerStore.setValidationErrors(null);

    const { response, error, validationErrors } = await withAsync(fetchRegister, payload);

    if (response) {
        registerStore.setStatusSuccess();
    } else if (validationErrors) {
        registerStore.setStatusValidationError();
        registerStore.setValidationErrors(validationErrors);
    } else if (error) {
        registerStore.setStatusError();
        registerStore.setMainError(error);
    }
}, 300);
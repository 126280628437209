import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchPicklist } from "@/api/helpersApi";

export const flowChannelsHandlerTimeout = ({ flowChannelsStore, payload }) => {
    setTimeout(() => requestTypesHandlerDebounced({ flowChannelsStore, payload }), 300);
};

const requestTypesHandlerDebounced = debounce(async function ({ flowChannelsStore, payload }) {
    flowChannelsStore.setStatusPending();
    flowChannelsStore.setMainError(null);

    const { response, error } = await withAsync(fetchPicklist, payload);

    if (response) {
        flowChannelsStore.setStatusSuccess();
        flowChannelsStore.flowChannels = response.data;
    } else if (error) {
        flowChannelsStore.setStatusError();
        flowChannelsStore.setMainError(error);
    }
}, 300);





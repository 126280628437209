import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchDealerCenters } from "@/api/helpersApi";

export const dealerCentersHandlerDebounced = debounce(async function ({ dealerCentersStore, payload }) {
    dealerCentersStore.setStatusPending();
    dealerCentersStore.setMainError(null);
    dealerCentersStore.setValidationErrors(null);

    const { response, error } = await withAsync(fetchDealerCenters, payload);

    if (response) {
        dealerCentersStore.setStatusSuccess();
        dealerCentersStore.dealerCenters = response.data.data;
    } else if (error) {
        dealerCentersStore.setStatusError();
        dealerCentersStore.setMainError(error);
    }
}, 300);
import { reactive } from 'vue';
export function useSort() {

    const sort = reactive({
        column: null,
        type: null
    });

    function toggleSort(column) {
        if (column === sort.column) {
            sort.type = sort.type === 'ASC' ? 'DESC' : 'ASC';
        }
        else {
            sort.column = column;
            sort.type = 'ASC';
        }
    }

    return {
        sort,
        toggleSort
    };
}
import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchLogin } from "@/api/authApi";

export const validationLoginHandler = ({ authStore, loginStore, loginForm, payload }) => {
    let validationErrors = {};

    if (loginForm.phone.length == 0) {
        (validationErrors.phone = []).push('Потрібно заповнити поле телефон.');
    }

    if (loginForm.password.length == 0) {
        (validationErrors.password = []).push('Потрібно заповнити поле пароль.');
    }

    if ((Object.keys(validationErrors).length !== 0)) {
        loginStore.setStatusValidationError();
        loginStore.setValidationErrors(validationErrors);
    }
    else {
        loginHandlerDebounced({ authStore, loginStore, payload });
    }
};

export const loginHandlerDebounced = debounce(async function ({ authStore, loginStore, payload }) {
    loginStore.setStatusPending();
    loginStore.setMainError(null);
    loginStore.setValidationErrors(null);

    const { response, error, validationErrors } = await withAsync(fetchLogin, payload);

    if (response) {
        loginStore.setStatusSuccess();
        authStore.login(response.data['data']);
    } else if (validationErrors) {
        loginStore.setStatusValidationError();
        loginStore.setValidationErrors(validationErrors);
    } else if (error) {
        loginStore.setStatusError();
        loginStore.setMainError(error);
    }
}, 300);
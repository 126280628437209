<template>
    <hr>
    <div class="form-check" v-for="dealer in dealerCentersStore.dealerCenters" :key="dealer.id">
        <input v-model="contractsStore.filters.dealerFilter" class="form-check-input" type="checkbox" :value="dealer.id"
            :id="'model-'+dealer.id">
        <label class="form-check-label" :for="'dealer-'+dealer.id">
            {{ dealer.text }}
        </label>
    </div>
</template>
<script setup>
    import { useAuthStore } from '@/stores/authStore';
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { useDealerCentersStore } from '@/stores/helpers/dealerCentersStore';
    import { watch, onMounted } from 'vue';
    import { dealerCentersHandlerDebounced } from '@/services/helpers/dealerCentersService';

    const authStore = useAuthStore();
    const contractsStore = useContractsStore();
    const dealerCentersStore = useDealerCentersStore();

    const updateDealerCenters = () => {
        const payload = {
            brand: authStore.brand
        };
        dealerCentersHandlerDebounced({ dealerCentersStore, payload });
    }

    const handlerBrandChange = () => {
        contractsStore.filters.dealerFilter = [];
        updateDealerCenters();
    }

    watch(() => authStore.brand, handlerBrandChange);

    onMounted(() => {
        updateDealerCenters();
    });

</script>
import { mainAPI } from './api';

const URLS = {
    searchContractsUrl: 'contracts/search',
    exportContractsUrl: 'contracts/export',
    clientContractsUrl: 'contracts/lead-card/client-contacts',
    planningTasksUrl: 'contracts/lead-card/planning-tasks',
    contractByTdriveIdUrl: 'contracts/get-contract-by-tdrive-id',
    clientVisitsByVinUrl: 'contracts/lead-card/get-client-visits-by-vin',
    registrationsHistoryByIdUrl: 'contracts/lead-card/get-registrations-history-by-id',
    registrationsByPhoneNumberUrl: 'contracts/lead-card/get-registrations-by-phone-number',
    commentsUrl: 'contracts/lead-card/comments',
    commentUrl: (commentId) => `contracts/lead-card/comments/${commentId}`,
};

export const fetchSearchContracts = (payload) => {
    return mainAPI.post(URLS.searchContractsUrl, payload);
};

export const fetchExportContracts = (payload) => {
    return mainAPI.post(URLS.exportContractsUrl, payload, { responseType: 'blob' });
};

export const fetchClientContacts = (payload) => {
    return mainAPI.get(URLS.clientContractsUrl, { params: payload });
}

export const fetchPlanningTasks = (payload) => {
    return mainAPI.get(URLS.planningTasksUrl, { params: payload });
}

export const fetchContractByTdriveId = (payload) => {
    return mainAPI.get(URLS.contractByTdriveIdUrl, { params: payload });
}

export const fetchClientVisitsByVin = (payload) => {
    return mainAPI.get(URLS.clientVisitsByVinUrl, { params: payload });
}

export const fetchRegistrationsHistoryById = (payload) => {
    return mainAPI.get(URLS.registrationsHistoryByIdUrl, { params: payload });
}

export const fetchRegistrationsByPhoneNumber = (payload) => {
    return mainAPI.get(URLS.registrationsByPhoneNumberUrl, { params: payload });
}



export const fetchComments = (payload) => {
    return mainAPI.get(URLS.commentsUrl, {
        params: payload,
        data: {
        }
    });
}

export const fetchCreateComment = (payload) => {
    return mainAPI.post(URLS.commentsUrl, payload);
}

export const fetchUpdateComment = (commentId, payload) => {
    return mainAPI.put(URLS.commentUrl(commentId), payload);
}

export const fetchDeleteComment = (commentId, payload) => {
    return mainAPI.delete(URLS.commentUrl(commentId), payload);
}
import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { ref } from 'vue';

export const useBookingCarsStore = defineStore('helpers.bookingCars', () => {
    const formStatus = useFormStatus();
    const bookingCars = ref([]);

    return {
        ...formStatus,
        bookingCars
    };
})
import { CITROEN, PEUGEOT, OPEL, DS_AUTOMOBILES, JEEP } from '@/constants/availableBrands';

export const getBrandImg = (brand) => {
    if (brand == CITROEN) {
        return require('@/assets/img/test/1.jpg');
    }
    else if (brand == PEUGEOT) {
        return require('@/assets/img/test/2.jpg');
    }
    else if (brand == OPEL) {
        return require('@/assets/img/test/3.jpg');
    }
    else if (brand == DS_AUTOMOBILES) {
        return require('@/assets/img/test/4.jpg');
    }
    else if (brand == JEEP) {
        return require('@/assets/img/test/1.jpg');
    }
    return false;
};

export const getBrandLogo = (brand) => {
    if (brand == CITROEN) {
        return require('@/assets/img/brands/new_logo_citroen_3.png');
    }
    else if (brand == PEUGEOT) {
        return require('@/assets/img/brands/new_logo_peugeot.png');
    }
    else if (brand == OPEL) {
        return require('@/assets/img/brands/200909_OPEL_vertical_white.png');
    }
    else if (brand == DS_AUTOMOBILES) {
        return require('@/assets/img/brands/new_logo_ds.png');
    }
    else if (brand == JEEP) {
        return require('@/assets/img/brands/jeep_logo.png');
    }
    return false;
};
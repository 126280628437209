import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchPicklist } from "@/api/helpersApi";

export const contractStatusesHandlerTimeout = ({ contractStatusesStore, payload }) => {
    setTimeout(() => contractStatusesHandlerDebounced({ contractStatusesStore, payload }), 300);
};

const contractStatusesHandlerDebounced = debounce(async function ({ contractStatusesStore, payload }) {
    contractStatusesStore.setStatusPending();
    contractStatusesStore.setMainError(null);

    const { response, error } = await withAsync(fetchPicklist, payload);

    if (response) {
        contractStatusesStore.setStatusSuccess();
        contractStatusesStore.contractStatuses = response.data;
    } else if (error) {
        contractStatusesStore.setStatusError();
        contractStatusesStore.setMainError(error);
    }
}, 300);





import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchFindCarByModelVersionId } from "@/api/helpersApi";

export const findCarByModelVersionIdHandlerTimeout = ({ bookingCarStore, payload }) => {
    setTimeout(() => findCarByModelVersionIdHandlerDebounced({ bookingCarStore, payload }), 300);
};

const findCarByModelVersionIdHandlerDebounced = debounce(async function ({ bookingCarStore, payload }) {
    bookingCarStore.setStatusPending();
    bookingCarStore.setMainError(null);

    const { response, error } = await withAsync(fetchFindCarByModelVersionId, payload);

    if (response) {
        bookingCarStore.setStatusSuccess();
        bookingCarStore.bookingCar = response.data;
    } else if (error) {
        bookingCarStore.setStatusError();
        bookingCarStore.setMainError(error);
    }
}, 300);
<template>
    <SidebarLeftLayout>
        <template v-slot:top-nav>
            <h4 class="text-primary">Контракти</h4>
        </template>
        <template v-slot:filters>
            <FiltersPart />
        </template>
        <template v-slot:default>
            <div class="container-fluid">
                <div class="bg-white px-3 pt-2 rounded min-vh-50 mt-0">
                    <TopBarPart />
                    <MainTablePart />
                </div>
                <PaginationPart />
            </div>
        </template>
    </SidebarLeftLayout>
</template>
<script setup>
    import { useAuthStore } from '@/stores/authStore';
    import { onMounted } from 'vue';
    import SidebarLeftLayout from '@/layouts/SidebarLeftLayout';
    import FiltersPart from './IndexParts/FiltersPart';
    import TopBarPart from './IndexParts/TopBarPart';
    import MainTablePart from './IndexParts/MainTablePart';
    import PaginationPart from './IndexParts/PaginationPart';
    const authStore = useAuthStore();
    onMounted(() => {
        authStore.checkBrand();
    });
</script>
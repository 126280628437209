import { AUTOMOBILE_DEALERSHIP, SERVICE_STATION, REPRESENTATION } from './availableOrganizations';

export const DIRECTORATE = 'Директорат (Салон+СТО)';
export const MARKETING = 'Маркетинг (Салон)';
export const NEW_HEAD_SALES = 'Керівник відділу продажу (Салон)';
export const NEW_HAS_ACCESS_TDRIVE = 'Консультант з продажу (Салон)';
export const NEW_HAS_ACCESS_NEWTDRIVE = 'Адміністратор відділу продажу (Салон)';
export const NEW_RESPONSIBLE_FOR_QUALITY = 'Відповідальний за якість (Салон+СТО)';

export const NEW_AFTERSALES_DEPT_HEAD = 'Керівник відділу ОПП (СТО)';
export const NEW_COMMERCIAL_SERVICE_CONSULTANT = 'Комерційний консультант з сервісу (СТО)';
export const NEW_SALES_DEPT_ADMIN = 'Адміністратор відділу ОПП (СТО)';
export const NEW_PRODUCTION_STAFF = 'Виробничий персонал (СТО)';

export const NEW_ACCESS_EMPLOYEES = 'Працівник Представництва';

export const getPositionsByOrganization = (organization) => {
    if (organization === AUTOMOBILE_DEALERSHIP) {
        return [DIRECTORATE, MARKETING, NEW_HEAD_SALES, NEW_HAS_ACCESS_TDRIVE, NEW_HAS_ACCESS_NEWTDRIVE, NEW_RESPONSIBLE_FOR_QUALITY]
    }
    if (organization === SERVICE_STATION) {
        return [DIRECTORATE, NEW_RESPONSIBLE_FOR_QUALITY, NEW_AFTERSALES_DEPT_HEAD, NEW_COMMERCIAL_SERVICE_CONSULTANT, NEW_SALES_DEPT_ADMIN, NEW_PRODUCTION_STAFF];
    }
    if (organization === REPRESENTATION) {
        return [NEW_ACCESS_EMPLOYEES];
    }
    return [];
};








import { withAsync } from "@/helpers/withAsync";
import { debounce } from "lodash";
import { fetchContractByTdriveId } from "@/api/contractsApi";

export const getContractByTdriveIdHandlerTimeout = ({ contractStore, payload }) => {
    setTimeout(() => getContractByTdriveIdHandlerDebounced({ contractStore, payload }), 300);
};

const getContractByTdriveIdHandlerDebounced = debounce(async function ({ contractStore, payload }) {
    contractStore.setStatusPending();
    contractStore.setMainError(null);

    const { response, error } = await withAsync(fetchContractByTdriveId, payload);

    if (response) {
        contractStore.setStatusSuccess();
        contractStore.contract = response.data;
    } else if (error) {
        contractStore.setStatusError();
        contractStore.setMainError(error);
    }
}, 300);





import { defineStore } from 'pinia';
import { useFormStatus } from '@/helpers/formStatus';
import { reactive } from 'vue';

export const useRegistrationsHistoryStore = defineStore('contracts.leadCard.registrationsHistory', () => {
    const formStatus = useFormStatus();
    const registrationsHistory = reactive({});

    return {
        ...formStatus,
        registrationsHistory
    };
})
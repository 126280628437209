<template>
    <div v-if="contractsStore.statusIsError" class="alert alert-danger flat mt-3" role="alert">
        {{ contractsStore.mainError }}
    </div>
    <div class="d-flex align-items-center m-3 text-primary" v-if="contractsStore.statusIsPending">
        <strong>Завантаження...</strong>
        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
    </div>
    <div class="table-responsive" v-if="contractsStore.contracts.length && !contractsStore.statusIsPending">
        <table class="table  table-sm  mt-3">
            <thead class="text-center align-middle">
                <tr>
                    <td>
                        <SortButtonBase label="Дилер" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_salon_name" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_salon_name')" />
                    </td>
                    <td>
                        <SortButtonBase label="Дата контракту" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_contractdate" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_contractdate')" />
                    </td>
                    <!-- <td>
                        <SortButtonBase label="Клієнт(ПІБ)" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="LastName" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('LastName')" />
                    </td> -->
                    <!-- <td>
                        <SortButtonBase label="Мобільний телефон" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="MobilePhone" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('MobilePhone')" />
                    </td> -->
                    <td>
                        <SortButtonBase label="Статус авто" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_warehouse" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_warehouse')" />
                    </td>
                    <td>
                        <SortButtonBase label="Номер кузова" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_vin" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_vin')" />
                    </td>
                    <td>
                        <SortButtonBase label="Номер РО" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_ordernumber" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_ordernumber')" />
                    </td>
                    <td>
                        <SortButtonBase label="Модель SAR" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_parentmodel" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_parentmodel')" />
                    </td>
                    <td>
                        <SortButtonBase label="Комплектація" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_parentmodel" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_parentmodel')" />
                    </td>
                    <td>
                        <SortButtonBase label="Тип оплати" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_contractstatus" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_contractstatus')" />
                    </td>
                    <td>
                        <SortButtonBase label="Статус контракту" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_vehiclestatus" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_vehiclestatus')" />
                    </td>
                    <td>
                        <SortButtonBase label=" Дата продажу" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_delivered" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_delivered')" />
                    </td>
                    <!-- <td>
                        <SortButtonBase label="Продавець" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_web_user_id" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_web_user_id')" />
                    </td> -->
                    <td>
                        <SortButtonBase label="Митне очищення" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_customsclearance" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_customsclearance')" />
                    </td>
                    <td>
                        <SortButtonBase label="Місцезнаходження авто" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_carlocation" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_carlocation')" />
                    </td>
                    <td>
                        <SortButtonBase label="Спосіб викупу" :currentSortColumn="contractsStore.sort.column"
                            sortColumn="New_arrivaltodealer" :sortType="contractsStore.sort.type"
                            @click.prevent="contractsStore.toggleSort('New_arrivaltodealer')" />
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr v-tippy="{ theme: 'stellantis',content: contract.redMessages.length>0? h(TooltipBase, { messages: contract.redMessages }):false}"
                    v-for="contract in contractsStore.contracts" :key="contract.tdriveId" :ref="v => singletons.push(v)"
                    @click="contractClickHandler(contract)" :class=" {'table-danger':contract?.redMessages?.length}">
                    <td>{{ contract.dealerName }} </td>
                    <td>{{ contract.contractDate }}</td>
                    <!-- <td>{{ contract.fullName }} </td> -->
                    <!-- <td>{{ contract.mobilePhone }}</td> -->
                    <td>{{ contract.warehouse }}</td>
                    <td>{{ contract.vehicleIdentificationNumber }}</td>
                    <td>{{ contract.orderNumber }}</td>
                    <td>{{ contract.parentCarName }}</td>
                    <td>{{ contract.carName }}</td>
                    <td>{{ contract.contractStatus }}</td>
                    <td>{{ contract.vehicleStatus }}</td>
                    <td>{{ contract.saleDate }}</td>
                    <!-- <td>{{ contract.manager }}</td> -->
                    <td>{{ contract.customsclearanceDate }}</td>
                    <td>{{ contract.carLocation }} </td>
                    <td>{{ contract.redemptionmethod }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script setup>
    import { ref, onMounted, watch, h } from 'vue';
    import { useAuthStore } from '@/stores/authStore';
    import { useContractsStore } from '@/stores/contracts/contractsStore';
    import { searchContractsHandlerTimeout } from '@/services/contracts/searchContractsService';
    import { useSingleton } from 'vue-tippy';
    import { useRouter } from "vue-router";
    import SortButtonBase from '@/components/base/SortButtonBase';
    import TooltipBase from '@/components/base/TooltipBase';

    const authStore = useAuthStore();
    const contractsStore = useContractsStore();
    const router = useRouter();

    const singletons = ref([])

    useSingleton(singletons, {
        arrow: true,
        interactive: true,
        placement: 'top',
        allowHTML: true,
        moveTransition: 'transform 0.2s ease-out',
    })

    const handleSortChange = () => {
        searchContractsHandlerTimeout({ authStore, contractsStore });
    }

    const contractClickHandler = (ctrt) => {
        router.push({ name: 'contracts.leadCard', params: { tdriveId: ctrt.tdriveId } });
    };

    watch(() => contractsStore.sort, handleSortChange, { deep: true });

    onMounted(() => {
        contractsStore.page = 1;
        contractsStore.perPage = 7;
        contractsStore.sort.column = 'New_contractdate';
        contractsStore.sort.type = 'DESC';
        searchContractsHandlerTimeout({ authStore, contractsStore });
    });
</script>
<style>
    .table td,
    .table th {
        white-space: normal !important;
        word-wrap: break-word !important;
        font-size: 12px !important;
    }
</style>